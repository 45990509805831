import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import HomePageImg from "../../resources/img/home2.jpg";

const Home2 = () => {
  const history = useHistory();
  return (
    <div style={{ width: "100%", position: 'relative' }}>
      <img
        src={HomePageImg}
        alt="Home page"
        style={{ width: "100%", opacity: "85%", filter: ' brightness(50%)' }}
      />
      <div
        style={{
          // backgroundColor: "rgba(0,0,0, 0.5)",
          width: "100%",
          height: "150px",
          textAlign: "center",
          position: "absolute",
          bottom: "40%",
        }}
      >
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "26pt",
            textShadow: "10px 10px 40px rgba(0, 0, 0, 0.9)",
            marginTop: "20px",
          }}
        >
          MAKE BETTER DECISION OF BUYING YOUR DREAM PROPERTY{" "}
        </div>
        <div
          style={{
            margin: "20px",
          }}
        >
          <Button
            className="airuma-btn-primary"
            onClick={() => history.push("/calculator")}
          >
            AIRUMA Calculator
          </Button>
        </div>
      </div>
    </div>
    // <div className="container">
    //   <Row>
    //     <Col>

    //     </Col>
    //   </Row>
    // </div>
  );
};

export default Home2;
