import { getLoanBankDetail } from "../services/services/calculator";

const defaultCalculatorValue = {
  totalmonthlyLoan: 0,
  otherIncome: 0,
  nettReportedIncome: 0,
  averageSixMonthBankCreditBalance: 0,
  averageSixMonthIncome: 0,
  nettIncome: 0,
  propertyPrice: 0,
  loanAmount: 0,
  desirePropertySize: 0,
  desirePaymentTerm: 0,
  maintenanceFeePerSqft: 0,
  downPayment: 0,
  isPropertyWithManagement: false,
  nrOfHousehold: 1,
  nrOfBorrower: 1,
  isCommercial: false,
  employementType: "FULLTIME",
  agreedTnC: false,
  loanInsuranceType: "INTO_LOAN",
  monthlyHouseLoanPayment: 0,
  monthlyVehicleLoanPayment: 0,
  otherMontlyLoan: 0,
  creditCardOutStanding: 0,
};

const debugDefaultValue = {
  creditCardOutStanding: 0,
  totalmonthlyLoan: 0,
  otherIncome: 0,
  nettReportedIncome: 0,
  averageSixMonthBankCreditBalance: 0,
  averageSixMonthIncome: 0,
  nettIncome: 5000,
  propertyPrice: 700000,
  loanAmount: 630000,
  desirePropertySize: 1300,
  desirePaymentTerm: 30,
  maintenanceFeePerSqft: 0.4,
  downPayment: 70000,
  isPropertyWithManagement: false,
  nrOfHousehold: 1,
  nrOfBorrower: 1,
  isCommercial: false,
  employementType: "FULLTIME",
  agreedTnC: false,
  loanInsuranceType: "INTO_LOAN",
  monthlyHouseLoanPayment: 0,
  monthlyVehicleLoanPayment: 0,
  otherMontlyLoan: 0,
};

const calculatorModel = {
  name: "calculator",
  state: {
    hasError: false,
    calculatorFormValue: { ...debugDefaultValue },
    elibibleBank: [
      {
        bankName: "UOM",
        bestRate: 3,
      },
    ],
    calculatorResult: {
      totalIncome: 0,
      totalCommitment: 0,
      currentDSR: 0,
      finalDSR: 0,
      estimatedInstallment: 0,
    },
  }, // initial state
  reducers: {
    setErrorState(state, payload) {
      return {
        ...state,
        hasError: payload,
      };
    },
    resetInput(state) {
      return {
        ...state,
        calculatorFormValue: { ...defaultCalculatorValue },
      };
    },
    // handle state changes with pure functions
    saveFormValue(state, payload) {
      return {
        ...state,
        calculatorFormValue: {...payload},
      };
    },
    saveLoanBankDetail(state, payload) {
      return {
        ...state,
        calculatorResult: {
          ...payload,
        },
      };
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions.
    async fetchListingDetail(payload) {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      // dispatch.listing.saveListings([
      //   'abcdfgh'
      // ])
    },
    async incrementAsync(payload, rootState) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch.listing.increment(payload);
    },
    async calculateLoanAmount(calculatorFormValue) {
      // getting bank info...
      // console.log(calculatorFormValue);

      try {
        dispatch.calculator.setErrorState(false)
        dispatch.calculator.saveFormValue(calculatorFormValue)
        const data = await getLoanBankDetail(calculatorFormValue);
        const result = {
          ...data.data,
        };
        dispatch.calculator.saveLoanBankDetail(result);
      } catch (err) {
        dispatch.calculator.setErrorState(true)
      }
      // await service.get (totalLoan, )
    },
  }),
};

export default calculatorModel;
