import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCheckSquare,
  // faSquare,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";

import { faSquare } from "@fortawesome/free-regular-svg-icons";

const Financial = (props) => {
  const { calculatorResult, selectedBank } = props;
  const {
    isMOTSelected,
    setIsMOTSelected,
    isSPALegalSelected,
    setIsSPALegalSelected,
    isLoanLegalSelected,
    setIsLoanLegalSelected,
    isLoanStampDutySelected,
    setIsLoanStampDutySelected,
    isValuationSelected,
    setIsValuationSelected,
    isConsentFeeSelected,
    setIsConsentFeeSelected,
    isFixtureSelected,
    setIsFixtureSelected,
  } = props;

  const banks = calculatorResult.banks;

  const [isUtilitiesDeposit, setIsUtilitiesDeposit] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (selectedBank) {
      const {
        downPayment,
        furnitureAndFixture,
        motFee,
        spaLegalFee,
        loanAgreementLegalFee,
        stampDutyForLoanAgreementFee,
        estimatedInsuranceFeeUpfront,
        valuation,
        consentFee,
        deposits,
      } = selectedBank;

      let tempTotalAmount =
        downPayment + estimatedInsuranceFeeUpfront;

      if (isFixtureSelected) {
        tempTotalAmount += furnitureAndFixture;
      }
      if (isMOTSelected) {
        tempTotalAmount += motFee;
      }
      if (isSPALegalSelected) {
        tempTotalAmount += spaLegalFee;
      }
      if (isLoanLegalSelected) {
        tempTotalAmount += loanAgreementLegalFee;
      }
      if (isLoanStampDutySelected) {
        tempTotalAmount += stampDutyForLoanAgreementFee;
      }
      if (isValuationSelected) {
        tempTotalAmount += valuation;
      }
      if (isConsentFeeSelected) {
        tempTotalAmount += consentFee;
      }
      if (isUtilitiesDeposit) {
        tempTotalAmount += deposits.electricity + deposits.water;
      }

      setTotalAmount(tempTotalAmount);
    }
  }, [
    selectedBank,
    isUtilitiesDeposit,
    isMOTSelected,
    isSPALegalSelected,
    isLoanLegalSelected,
    isLoanStampDutySelected,
    isValuationSelected,
    isConsentFeeSelected,
    isFixtureSelected,
  ]);

  return (
    <Row>
      <Col md="8" xs="12">
        <p className="calculation-tab-description">
          To own a house, you will also need to pay some upfront fees after
          signing the Sales and Purchase Agreement (SPA). AIRUMA breaks down the
          common upfront fees in the table below. Based on applicable government
          policy, some upfront fee may vary.
        </p>
        <Row>
          <Col xs="12">
            <Table>
              <thead>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faInfoCircle} id="applicable" />
                    <UncontrolledTooltip target="applicable">
                      Tick the checkbox if applicable
                    </UncontrolledTooltip>
                  </th>
                  <th>Items</th>
                  <th>Estimated amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>Downpayment</td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={(selectedBank && selectedBank.downPayment) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Utility deposit &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="utilityDeposit" />
                    <UncontrolledTooltip target="utilityDeposit">
                      This includes water and electricity deposit
                    </UncontrolledTooltip>
                  </td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        (selectedBank &&
                          selectedBank.deposits.electricity +
                            selectedBank.deposits.water) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Estimated loan insurance (MRTA) &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="mrta" />
                    <UncontrolledTooltip target="mrta">
                      Mortgage Reducing Term Assurance (MRTA) is a life
                      insurance that pays your outstanding home loan in event of
                      your death or total permanent disability (TPD). It is
                      usually packaged as an option when applying for a home
                      loan at a bank.
                    </UncontrolledTooltip>
                  </td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        (selectedBank &&
                          selectedBank.estimatedInsuranceFeeUpfront) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td onClick={() => setIsFixtureSelected(!isFixtureSelected)}>
                    {isFixtureSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>
                    Furniture and fixture &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="fixture" />
                    <UncontrolledTooltip target="fixture">
                      Estimated minimum furniture / renovation fee based on the
                      size of the property
                    </UncontrolledTooltip>
                  </td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        (selectedBank && selectedBank.furnitureAndFixture) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td onClick={() => setIsMOTSelected(!isMOTSelected)}>
                    {isMOTSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>MOT (Memorandum of Transfer) - Stamp Duty</td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={(selectedBank && selectedBank.motFee) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => setIsSPALegalSelected(!isSPALegalSelected)}
                  >
                    {isSPALegalSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>SPA legal fee</td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={(selectedBank && selectedBank.spaLegalFee) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => setIsLoanLegalSelected(!isLoanLegalSelected)}
                  >
                    {isLoanLegalSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>Loan agreement legal fee</td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        (selectedBank && selectedBank.loanAgreementLegalFee) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() =>
                      setIsLoanStampDutySelected(!isLoanStampDutySelected)
                    }
                  >
                    {isLoanStampDutySelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>Loan agreement stamp duty</td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        (selectedBank &&
                          selectedBank.stampDutyForLoanAgreementFee) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>Additional cost of purchasing</td>
                  <td>
                    {" "}
                    {(banks && banks.length > 0 && banks[0].additionalFee) ||
                      0.0}
                  </td>
                </tr> */}

                <tr>
                  <td
                    onClick={() => setIsValuationSelected(!isValuationSelected)}
                  >
                    {isValuationSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>
                    Valuation fee &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="valuation" />
                    <UncontrolledTooltip target="valuation">
                      Valuation fee is the valuation report fee you have to pay
                      to the valuer. The valuation report is needed when the
                      property you buy is completed.
                    </UncontrolledTooltip>
                  </td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={(selectedBank && selectedBank.valuation) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() =>
                      setIsConsentFeeSelected(!isConsentFeeSelected)
                    }
                  >
                    {isConsentFeeSelected ? (
                      <FontAwesomeIcon icon={faCheckSquare} />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} />
                    )}
                  </td>
                  <td>
                    Consent fee &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="consentFee" />
                    <UncontrolledTooltip target="consentFee">
                      Consent fees applies when you need to apply from State
                      Authority.
                    </UncontrolledTooltip>
                  </td>
                  <td>
                    <span className="calculator-currency">
                      {calculatorResult.currency}{" "}
                    </span>
                    <NumberFormat
                      className="calculator-amount"
                      value={(selectedBank && selectedBank.consentFee) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                </tr>

                {/* <tr>
                  <td>
                    Agent fee &nbsp;
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="consentFee"
                    />
                    <UncontrolledTooltip target="consentFee">
                      Agent fee will always applicable when purchasing 
                    </UncontrolledTooltip>
                  </td>
                  <td>AGENT FEE</td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
          {/* <Col xs="4">
            <Card>
              <CardHeader>MOT</CardHeader>
              <CardBody style={{ minHeight: "100px" }}>
                {(banks && banks.length > 0 && banks[0].motFee) || 0.0}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader>SPA legal fee</CardHeader>
              <CardBody style={{ minHeight: "100px" }}>
                {(banks && banks.length > 0 && banks[0].spaLegalFee) || 0.0}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader> </CardHeader>
              <CardBody style={{ minHeight: "100px" }}>
                {(banks && banks.length > 0 &&
                  banks[0].loanAgreementLegalFee) ||
                  0.0}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader>Loan agreeemnt stamp duty</CardHeader>
              <CardBody style={{ minHeight: "100px" }}>
                {(banks && banks.length > 0 &&
                  banks[0].stampDutyForLoanAgreementFee) ||
                  0.0}
              </CardBody>
            </Card>
          </Col>
          <Col xs="4">
            <Card>
              <CardHeader>Estimated loan insurance</CardHeader>
              <CardBody style={{ minHeight: "100px" }}>
                {(banks && banks.length > 0 &&
                  banks[0].estimatedInsuranceFee) ||
                  0.0}
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Col>
      <Col md="4" xs="12" style={{ height: "100%" }}>
        <Card className="calculator-upfront-total">
          <CardBody style={{ textAlign: "center" }}>
            <span style={{ fontSize: "18px" }}>Total amount</span>
            <br />
            <span style={{ fontSize: "30px", fontWeight: "bold" }}>
              <span className="calculator-currency">
                {calculatorResult.currency}{" "}
              </span>
              <NumberFormat
                className="calculator-amount"
                value={totalAmount || 0.0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Financial;
