import React from "react";
import PrivacyComponent from '../../../components/Polices/Privacy'

const Privacy = () => {
  return (
    <div className="container">
     <PrivacyComponent />
    </div>
  );
};

export default Privacy;
