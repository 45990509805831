import * as Yup from "yup";

const CalculatorSchema = Yup.object().shape({
  agreedTnC: Yup.bool()
    .oneOf([true], "The terms and conditions must be accepted.")
    .required(),
  propertyPrice: Yup.number().moreThan(0, 'Property price must larger than zero.').required('Please input property price.')
  // email: Yup.string().email("Invalid email").required("Required"),
});

const ReportSchema = Yup.object().shape({
  email: Yup.string().email()
    .required('Please input valid email.'),
  // email: Yup.string().email("Invalid email").required("Required"),
});

export { CalculatorSchema, ReportSchema };
