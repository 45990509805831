import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap'

const DetailCardAmenties = (props) => {
  const { amenties } = props
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={{ size: 8 }} style={{ minHeight: '200px'}}>
            <h5>Nearby amenties</h5>
            <div>
              <span>School: </span>
              <span>
                {
                  amenties.schools.map((e) => e.name)
                }
              </span>
            </div>
            <div>
              <span>Public transport: </span>
              <span>
                {
                  amenties.publicTransports.map((e) => e.name)
                }
              </span>
            </div>
            <div>
              <span>Mall: </span>
              <span>
                {
                  amenties.malls.map((e) => e.name)
                }
              </span>
            </div>
            <div>
              <span>Restaurant: </span>
              <span>
                {
                  amenties.restaurants.map((e) => e.name)
                }
              </span>
            </div>
            <div>
              <span>Religious facility: </span>
              <span>
                {
                  amenties.religiousFacility.map((e) => e.name)
                }
              </span>
            </div>
          </Col>
          <Col md={{ size: 4 }}>
            MAP again
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
};

export default DetailCardAmenties;
