import React, { useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Button,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";

import DetailCardTop from "./components/DetailCardTop";
import DetailCardBottom from "./components/DetailCardAmenties";
import DetailCardOtherOption from "./components/DetailCardOtherOptions";

import "./style.css";

const DetailPage = (props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  const { detail, loading } = props;
  const { unitDetail, amenties, nearbyOptions } = detail;

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const Footer = () => (
    <footer className="detail-footer">
      <div className="float-right">
        <Button
          color="primary"
          onClick={() => {
            history.push("/booking");
          }}
        >
          Contact agent now
        </Button>
      </div>
    </footer>
  );

  return (
    <>
      <div className="container-fluid">
        <LoadingOverlay
          active={loading.effects.detail.fetchListingDetail}
          spinner
          text="Loading your content..."
        >
          <Row className="firstDetailRow">
            <Col xs={{ size: 8, offset: 1 }}>
              <DetailCardTop unitDetail={unitDetail} />
            </Col>
          </Row>
          <Row className="tabDetailRow">
            <Col xs={{ size: 8, offset: 1 }}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Detail
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    VR View
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <DetailCardBottom amenties={amenties} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <DetailCardOtherOption nearbyOptions={nearbyOptions} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="6">1</Col>
                    <Col sm="6">2</Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </LoadingOverlay>
      </div>
      <Footer />
    </>
  );
};

export default connect(({ detail, loading }) => ({ detail, loading }))(
  DetailPage
);
