export default {
  name: "listing",
  state: {
    listings: [{
      lat: 1.2222,
      long: 2.2222,
      name: 'The Vyne Type A',
      propertyType: 'condominium', // landed, service apertment
      buildingType: 'highrise', //landed
      state: 'Kuala Lumpur',
      description: 'Description here...',
      startingPrice: 1234039.00,
      bedroomCount: 1,
      bathroomCount: 1,
      unitArea: 1234,
      areaMeasurementUnit: 'sqft',
      totalStorey: 40,
      locatedStorey: 20,
      tenure: 'leasehold', //freehold
      propertyTitle: 'Commercial', // individual, strata, master
      estimatedmonthlyPayment: 1234.00,
      currency: 'RM',
      imgSrc: '',
    },
    {
      lat: 1.2222,
      long: 2.2222,
      name: 'The Vyne Type B',
      propertyType: 'condominium', // landed, service apertment
      buildingType: 'highrise', //landed
      state: 'Kuala Lumpur',
      description: 'Description here...',
      startingPrice: 1234039.00,
      bedroomCount: 1,
      bathroomCount: 1,
      unitArea: 1234,
      areaMeasurementUnit: 'sqft',
      totalStorey: 40,
      locatedStorey: 20,
      tenure: 'leasehold', //freehold
      propertyTitle: 'Commercial', // individual, strata, master
      estimatedmonthlyPayment: 1234.00,
      currency: 'RM',
      imgSrc: '',
    }]
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload;
    },
    saveListings(state, payload) {
      return {
        ...state,
        listings: payload
      }
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions.
    async fetchListing(payload) {
      console.log(`search for payload ${payload}`)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // dispatch.listing.saveListings([
      //   'abcdfgh'
      // ])
    },
    async incrementAsync(payload, rootState) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch.listing.increment(payload);
    },
  }),
};
