import React, { useState } from "react";
import {
  FacebookIcon,
  EmailIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";

const Share = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const shareUrl = "www.airuma.com.my/calculator";

  return (
    <Card>
      <CardBody>
        <div style={{ marginBottom: "20px" }}>
          <span>Find it is useful? Help us spread to world!</span>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <FacebookShareButton size={32} round url={shareUrl} />
          <TelegramShareButton size={32} round url={shareUrl} />
          <TwitterShareButton size={32} round url={shareUrl} />
          <WhatsappShareButton size={32} round url={shareUrl} />
          <EmailShareButton size={32} round url={shareUrl} />

          {/* <FacebookIcon size={32} round url={shareUrl} />
          <WhatsappIcon size={32} round url={shareUrl} />
          <TwitterIcon size={32} round url={shareUrl} />
          <TelegramIcon size={32} round url={shareUrl} />
          <EmailIcon size={32} round url={shareUrl} /> */}
        </div>
        <div>
          <Row>
            <Col xs="12" md="3">
              <InputGroup
                onClick={() => {
                  navigator.clipboard.writeText(shareUrl);
                  setIsCopied(true);
                }}
              >
                <Input value={shareUrl} />
                <InputGroupAddon addonType="append">
                  <InputGroupText style={{ cursor: "pointer" }}>
                    {isCopied ? "Copied!" : "Copy"}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default Share;
