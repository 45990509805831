import React from 'react';
import { Card, CardBody, Row, Col, Input, Button, Badge } from "reactstrap";

import ResultCard from './components/ResultCard';
import BingMap from './map';
import { connect } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';

import './style.scss'

import Filter from './components/Filter'

const ResultPage = (props) => {
  const { loading, listing } =  props;
  return (
    <div className="resultContainer">
      <LoadingOverlay
        active={loading.effects.listing.fetchListing}
        spinner
        text='Loading your content...'
        >
        <Row className="filterRow">
          <Col xs={{ offset: 1, size: 10}}>
            <Filter />
          </Col>
        </Row>
        <Row className="resultRow">
          <Col xs={{ size: 6, offset: 1}}>
            {
              listing.listings.map(e => (
                <ResultCard property={e} />
              ))
              
            }
          </Col>
          <Col xs="4" style={{marginTop: '15px'}}>
            <Card>
              <CardBody>
                <div style={{ height: '500px' }}>
                <BingMap
                  mapOptions={{
                    center: [47.60357, -122.32945],
                    credentials:
                      "AjwUEXFZA8SMyy8CaJj59vJKVDoWohNXVFz_uGyHlT8N40Jgr-zrhvcxbTNRyDqn"
                  }}
                />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingOverlay>
      
    </div>
  );
}

export default connect(({ listing, loading }) => ({ listing, loading }))(ResultPage);
