import React from "react";
import { Row, Col } from "reactstrap";
import AboutUsImg from "../../resources/img/about-us.jpg";

const AboutUs = () => {
  return (
    <Row style={{ marginBottom: "-5%" }}>
      <Col xs="12" md="6" style={{ minHeight: "300px" }}>
        <div>
          <img src={AboutUsImg} alt="About us" style={{ width: "100%" }} />
        </div>
      </Col>
      <Col>
        <div style={{ margin: "5%" }}>
          <h3>About AIRUMA</h3>
          <div style={{ textAlign: "justify", textJustify: "space-evenly" }}>
            <p>
              AIRUMA aims to help home buyers to understand their financials
              better before purchasing their dream home so that their dream
              would not become a disaster.
            </p>
            <p>
              The reason why AIRUMA Property Calculator is created is that
              AIRUMA's co-founder, who is an experienced banker, realized that
              most of the home buyers do not know clearly what are the
              underlying costs of possessing property. Most home buyers only
              wish to know about the installment amount and financing rate and
              they may have underestimated the commitment that they have to bear
              every month.
            </p>
            <p>
              With this calculator, we are able to help home buyers to compare
              eligible banks, inform them of their estimated monthly expenses
              and upfront costs, where most inexperienced home buyers may have
              neglected.
            </p>
            <p>
              AIRUMA also would like to help home buyers to have a clearer
              picture of their financial situation, and we can consolidate and
              generate a report which will explain the information in a more
              detailed manner. We had even crafted an AIRUMA Stress Index to
              assist homebuyers in making their decision.
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AboutUs;
