import React from 'react';
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';

const Step1 = () => {

  return (
    <>
    <span>Step 1: we would like to get some info before proceed.</span>
     <FormGroup>
        <Label for="exampleEmail">Commitement year</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Estimated loan</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Person under loan</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Contact person full name</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Age of loan applicant</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Income of application (net income)</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
      <FormGroup>
        <Label for="exampleEmail">Source of income</Label>
        <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
      </FormGroup>
    </>
  )
}

export default Step1;
