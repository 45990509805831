import React from 'react';
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { connect } from 'react-redux'
import Multistep from 'react-multistep';

import StepOne from './components/Step1';
import StepTwo from './components/Step2';
import StepThree from './components/Step3';
// import StepFour from './components/Step3';

const BookingPage = (props) => {
  const { booking } = props;
  const { unitDetail } = booking;
  
  const steps = [
    {name: 'Basic info', component: <StepOne/>},
    {name: 'StepTwo', component: <StepTwo/>},
    {name: 'StepThree', component: <StepThree/>},
    // {name: 'StepFour', component: <StepFour/>}
  ];

  return (
    
      <Row>
        <Col xs="4">
          <Card style={{ marginTop: '20%'}}>
            <CardBody>
            <div>{ unitDetail.name }</div>
            <div>
              { `${unitDetail.unitArea} ${unitDetail.areaMeasurementUnit}` }
            </div>
            <div>
              { `Storey: ${unitDetail.locatedStorey}`}
            </div>
            <div>
              { `Unit: ${unitDetail.locatedUnitNumber}`}
            </div>
            <div>
              { `${unitDetail.currency} ${unitDetail.startingPrice}` }
            </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="8">
          <Multistep showNavigation={true} steps={steps}/>
        </Col>
      </Row>
    
  )
}

export default connect(({ booking }) => ({ booking }))(BookingPage);
