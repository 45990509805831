import React, { useState } from "react";

import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import airumaIcon from "../../resources/img/airuma-logo.png";

const NavPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="airuma-navigation" light expand="md">
      <NavbarBrand href="/" className="airuma-nav-link">
        <img src={airumaIcon} alt="AIRMUA" style={{ height: "30px" }} />
      </NavbarBrand>
      {/* <NavbarToggler onClick={toggle} /> */}
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem className="airuma-nav-link">
            <NavLink className="airuma-nav-link" href="/calculator">
              <FontAwesomeIcon icon={faCalculator} />
              {"    "}
              Calculator
            </NavLink>
          </NavItem>
          {process.env.IS_LOCAL === "true" ? (
            <>
              <NavItem>
                <NavLink className="airuma-nav-link" href="/blog/learn/">
                  Learn
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="airuma-nav-link" href="/components/">
                  News
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="airuma-nav-link" href="/components/">
                  Editor choice
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="airuma-nav-link" href="/components/">
                  New launch
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="airuma-nav-link" href="/components/">
                  Find agent
                </NavLink>
              </NavItem>
            </>
          ) : undefined}
          {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
        </Nav>
        {/* <NavbarText>Login</NavbarText>
        &nbsp;&nbsp;&nbsp;
        <NavbarText>Register</NavbarText> */}
      </Collapse>
    </Navbar>
  );
};

export default NavPanel;
