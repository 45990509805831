import React, { Suspense } from "react";
import { Container } from "reactstrap";

import { Switch, Router, Route } from "react-router-dom";
import routes from "../routes";
import FooterComponent from "./components/Footer";
import NavPanel from "./components/NavPanel";

const MainLayout = () => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  function RouteWithSubRoutes(route) {
    // A special wrapper for <Route> that knows how to
    // handle "sub"-routes by passing them in a `routes`
    // prop to the component it renders.
    return (
      <Route
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }

  return (
    <div className="App">
      <NavPanel />

      <Container className="mt-2 pl-0 pr-0" fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
            {/* {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))} */}
          </Switch>
        </Suspense>
      </Container>
      {/* <div className="layout-footer">
          abc
      </div> */}

      <FooterComponent />
    </div>
  );
};

export default MainLayout;
