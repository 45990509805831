import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  Table,
  Modal,
  ModalBody,
  Button,
  ModalHeader,
} from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";

const getAriumaTableStyle = (value) => {
  return classnames({
    "airuma-stress-index-row-selected": value,
  });
};

const defaultData = {
  datasets: [
    {
      data: [10, 20, 30],
    },
  ],
  options: {
    elements: {
      center: {
        text: "Red is 2/3 of the total numbers",
        color: "#FF6384", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25, // Default is 25 (in px), used for when text wraps
      },
    },
  },
  // These labels appear in the legend and in the tooltips when hovering different arcs
  labels: ["Red", "Yellow", "Blue"],
};

const Financial = (props) => {
  const { calculatorResult, selectedBank, setChartConfig } = props;
  const [data, setData] = useState(defaultData);
  const [plugins, setPlugins] = useState();
  const [isStressIndexModalOpen, setIsStressIndexModalOpen] = useState(false);

  useEffect(() => {
    if (selectedBank) {
      const plugins = [
        {
          beforeDraw: function (chart) {
            const width = chart.width;
            const height = chart.height;
            const ctx = chart.ctx;
            console.log(chart)
            ctx.restore();

            var fontSize = (height / 320).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            const text = "AIRUMA Stress Index"
              //   ''
              //     // <>
              //     //   AIRUMA Stress Index&trade;
              //     //   {selectedBank &&
              //     //     Math.round(selectedBank.airumaIndex.airumaStressLevel * 100) /
              //     //       100}
              //     // </>
              //   )
            let textX = Math.round((width - ctx.measureText(text).width) / 2)
            let textY = height / 3.5;
            ctx.fillText(text, textX, textY);
            const level =
              selectedBank &&
              Math.round(selectedBank.airumaIndex.airumaStressLevel * 100) /
                100;
            ctx.font = "800 " + fontSize * 1.8 + "em sans-serif";
            textX = Math.round((width - ctx.measureText(level).width) / 2);
            ctx.fillText(level, textX, textY * 1.3);
            ctx.save();
          },
        },
      ];
      setPlugins(plugins);
    }
  }, [selectedBank]);

  useEffect(() => {
    if (selectedBank) {
      const {
        electricity,
        water,
        internet,
        managementFee,
        repairMaintenanceReserve,
        sewerage,
        totalUtilities,
      } = selectedBank.averageUtility;

      const tempRemaining =
        selectedBank.totalIncome -
        selectedBank.currentDebtOutstanding -
        selectedBank.airumaIndex.totalMonthlyExpenses;
      // selectedBank.monthlyPayment; - monthly payment has been included in total monthly expeses calculation

      const datasets = [
        {
          data: [
            // tempRemaining,
            selectedBank.currentDebtOutstanding,
            totalUtilities,
            selectedBank.monthlyPayment,
            tempRemaining,
          ],
          backgroundColor: [
            // "rgb(159, 249, 224)",
            "#FF5050",
            "#FF7C80",
            "#FF9999",
            "#BFBFBF",
          ],
          labels: ["x", "y", "y", "y"],
        },
      ];

      const labels = [
        // "Balance",
        `Current commitment - ${(
          (selectedBank.currentDebtOutstanding * 100) /
          selectedBank.totalIncome
        ).toFixed(2)} %`,
        `Other monthly expenses - ${(
          (totalUtilities * 100) /
          selectedBank.totalIncome
        ).toFixed(2)} %`,
        `Property installment - ${(
          (selectedBank.monthlyPayment * 100) /
          selectedBank.totalIncome
        ).toFixed(2)} %`,
        `Cashflow - ${(
          (tempRemaining * 100) /
          selectedBank.totalIncome
        ).toFixed(2)}%`,
      ];

      const chartData = {
        datasets,
        labels,
      };
      setData(chartData);

      setChartConfig(chartData);
    }
  }, [selectedBank, setChartConfig]);

  const toggleStressIndexModalOpen = () => {
    setIsStressIndexModalOpen(!isStressIndexModalOpen);
  };

  return (
    <div>
      <Modal
        isOpen={isStressIndexModalOpen}
        toggle={toggleStressIndexModalOpen}
      >
        <ModalHeader toggle={toggleStressIndexModalOpen}>
          AIRUMA stress index explanation
        </ModalHeader>
        <ModalBody></ModalBody>
      </Modal>
      <Row>
        <Col xs="12" md="3">
          <Row>
            <Col xs="12" className="calculator-financial-airuma-widget-col">
              <Card className="calculator-financial-airuma-widget">
                <CardBody>
                  <p className="calculator-financial-title">
                    Nett income{" "}
                    <span className="calculator-currency">
                      ({calculatorResult.currency})
                    </span>
                  </p>
                  <span className="calculator-financial-content">
                    <NumberFormat
                      className="calculator-amount"
                      decimalScale={2}
                      value={(selectedBank && selectedBank.totalIncome) || 0.0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className="calculator-financial-airuma-widget-col">
              <Card className="calculator-financial-airuma-widget">
                <CardBody>
                  <p className="calculator-financial-title">
                    Total loan amount{" "}
                    <span className="calculator-currency">
                      ({calculatorResult.currency})
                    </span>
                  </p>
                  <span className="calculator-financial-content">
                    <NumberFormat
                      className="calculator-amount"
                      decimalScale={2}
                      value={
                        (selectedBank && selectedBank.totalLoanAmount) ||
                        0.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <span className="calculator-financial-title">
                    {calculatorResult &&
                    calculatorResult.loanInsuranceType === "INTO_LOAN"
                      ? " (Included MRTA)"
                      : ""}
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className="calculator-financial-airuma-widget-col">
              <Card className="calculator-financial-airuma-widget">
                <CardBody>
                  <p className="calculator-financial-title">
                    DSR before loan&nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="dsrBefore" />
                    <UncontrolledTooltip target="dsrBefore">
                      DSR, or Debt Service Ratio, is calculated by dividing
                      total commitment with income. It is an indicator used by
                      bank to decide if the individual is eligible for the loan.
                      Different banks have different acceptable DSR.
                    </UncontrolledTooltip>
                  </p>
                  <span className="calculator-financial-content">
                    {(selectedBank &&
                      selectedBank.dsrBeforeLoan &&
                      selectedBank.dsrBeforeLoan.toFixed(2)) ||
                      0.0}
                    &nbsp; %
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className="calculator-financial-airuma-widget-col">
              <Card className="calculator-financial-airuma-widget">
                <CardBody>
                  <p className="calculator-financial-title">
                    DSR after loan &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="dsrAfter" />
                    <UncontrolledTooltip target="dsrAfter">
                      Indicating the DSR after succesfully apply the loan. This
                      is useful if there is plan for applying other loan in the
                      near future.
                    </UncontrolledTooltip>
                  </p>
                  <span className="calculator-financial-content">
                    {(selectedBank &&
                      selectedBank.dsrAfterLoan &&
                      selectedBank.dsrAfterLoan.toFixed(2)) ||
                      0.0}
                    &nbsp; %
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
          <Col xs="4">
              <Doughnut data={data} />
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs="12">
              <Card style={{ top: "15%" }}>
                <CardBody className="calculator-financial-airuma-index-text">
                  Note: If your Debt Service Ratio or DSR (before loan) is lesser than 70%, you may have a better chance of getting loan from the bank.
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Col>

        <Col md="5" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <Card>
            <CardBody>
              {/* <div className="float-right">
                <Button color="info" onClick={toggleStressIndexModalOpen}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
              </div> */}
              <div style={{ textAlign: "center", width: "100%" }}>
                {plugins ? (
                  <Doughnut
                    data={data}
                    id="doughnut"
                    plugins={plugins}
                    options={{
                      // responsive: false,
                      maintainAspectRatio: false,
                     
                      cutout: "70%",
                      plugins: {
                        legend: {
                          display: true,
                          position: "bottom",
                          align: "start",
                          maxWidth: 200,
                          // fullSize: false,
                        },
                      },
                    }}
                  />
                ) : undefined}

                {/* <h4>AIRUMA Stress Index&trade;</h4>
                <span>
                  <h3>
                    {selectedBank &&
                      Math.round(
                        selectedBank.airumaIndex.airumaStressLevel * 100
                      ) / 100}
                  </h3>
                </span> */}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <div style={{ textAlign: "left", width: "100%" }}>
            <p className="calculator-financial-airuma-index-text">
              AIRUMA Stress Index&trade; uses an unique formula to assess your
              financial condition after obtaining a loan to purchase the
              property.
            </p>
            <Table className="calculator-financial-airuma-index-text">
              <tbody>
                <tr
                  className={getAriumaTableStyle(
                    selectedBank &&
                      selectedBank.airumaIndex &&
                      selectedBank.airumaIndex.airumaStressLevel < 40
                  )}
                >
                  <td style={{ width: "20%" }}>40 below</td>
                  <td>
                    Congratulations! Your cash flow is not affected
                    significantly after buying this property. You have a
                    considerable amount of cash flow for other property
                    investments.
                  </td>
                </tr>
                <tr
                  className={getAriumaTableStyle(
                    selectedBank &&
                      selectedBank.airumaIndex &&
                      selectedBank.airumaIndex.airumaStressLevel >= 41 &&
                      selectedBank.airumaIndex.airumaStressLevel <= 80
                  )}
                >
                  <td>40 - 80</td>
                  <td>
                    You have a reasonable amount of cash flow after buying this
                    property and you may consider lighter investments.
                  </td>
                </tr>
                <tr
                  className={getAriumaTableStyle(
                    selectedBank &&
                      selectedBank.airumaIndex &&
                      selectedBank.airumaIndex.airumaStressLevel > 80
                  )}
                >
                  <td>80 above</td>
                  <td>
                    Your cash flow is restricted after buying this property.
                    Consider other less expensive properties or increase your
                    monthly income.
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        {/* <Col xs="4">
        
        </Col> */}
      </Row>
    </div>
  );
};

export default Financial;
