import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faShower,
  faBuilding,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import ResultBasicDetail from './ResultBasicDetail';
import "./style.css";
import defaultImg from '../../../resources/img/logo192.png'
import Images from './ImagesSlides';

const ResultCard = (props) => {
  const { unitDetail: property } = props;
  const {
    lat,
    long,
    name,
    state,
    description,
    startingPrice,
    buildingType,
    propertyType,
    bedroomCount,
    bathroomCount,
    unitArea,
    areaMeasurementUnit,
    totalStorey,
    locatedStorey,
    tenure, //freehold
    propertyTitle, // individual, strata, master
    estimatedmonthlyPayment,
    currency,
    imgSrc
  } = property;
  
  const getStoreyRangeText = (located, total) => {
    const result = located/total
    let rangeText
    if (result < 25) {
      rangeText = 'low'
    } else if (result < 50) {
      rangeText = 'lower high'
    } else if (result < 75) {
      rangeText = 'higher low'
    } else {
      rangeText = 'high'
    }
    return rangeText
  }

  return (
    <div className="detailTopCard">
      <div style={{ width: "35%", textAlign: 'center', borderRight: 'solid 1px rgba(0,0,0,.125)' }}>
        {/* <img src={imgSrc === '' || !imgSrc ? defaultImg : imgSrc} alt="Propety"/> */}
        <Images />
      </div>
      <div style={{ width: "65%", margin: "10px" }}>
        <h4>{name}</h4>
        <span>{state}</span>
        <br />
        <span>{description}</span>
        <div>
          <span style={{ fontSize: "small" }}>from </span>
          <span style={{ fontWeight: "bold", fontSize: "large" }}>
            {currency} {estimatedmonthlyPayment}/month
          </span>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "small" }}>
            {currency} {startingPrice}
          </span>
        </div>
        <br />
        <div style={{ display: 'flex'}}>
          <ResultBasicDetail>
            {bedroomCount} <FontAwesomeIcon icon={faBed} />
          </ResultBasicDetail>
          <ResultBasicDetail>
            {bathroomCount} <FontAwesomeIcon icon={faShower} />
          </ResultBasicDetail>
         {buildingType === "highrise" ? (
            <ResultBasicDetail>
              {getStoreyRangeText(locatedStorey, totalStorey)} <FontAwesomeIcon icon={faBuilding} />
            </ResultBasicDetail>
          ) : undefined}

          {buildingType === "landed" ? (
            <ResultBasicDetail>
              {totalStorey} Storey <FontAwesomeIcon icon={faHome} />
            </ResultBasicDetail>
          ) : undefined}

          <span>{unitArea} {areaMeasurementUnit}</span>

         
        </div>
        <div>
          <div style={{ marginTop: "10px" }}>
            <div className="result-badge-container">
              <span className="result-badge">{tenure}</span>
            </div>
            <div className="result-badge-container">
              <span className="result-badge">{propertyType}</span>
            </div>
            <div className="result-badge-container">
              <span className="result-badge">{propertyTitle}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResultCard.propTypes = {
  property: PropTypes.object,
};

ResultCard.defaultProps = {
  property: {
    lat: 1.2222,
    long: 2.2222,
    name: 'The Vyne',
    propertyType: 'condominium', // landed, service apertment
    buildingType: 'highrise', //landed
    state: 'Kuala Lumpur',
    description: 'Default location text',
    startingPrice: 1234039.00,
    bedroomCount: 1,
    bathroomCount: 1,
    unitArea: 1234,
    areaMeasurementUnit: 'sqft',
    totalStorey: 40,
    locatedStorey: 20,
    tenure: 'leasehold', //freehold
    propertyTitle: 'Commercial', // individual, strata, master
    estimatedmonthlyPayment: 1234.00,
    currency: 'RM',
    imgSrc: defaultImg,
  },
};

export default ResultCard;
