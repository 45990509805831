import React from 'react';
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux'

const Step1 = (props) => {
  const { loading } = props;

  return (
    <>
    <LoadingOverlay
      active={loading.effects.listing.checkCCRIS}
      spinner
      text='CCRIS Checking...'
    >

<div>
                <span>Before proceeding, we would like to request some documents. This is optional, but will accelerate the purchase process.</span>
              </div>
              <div>
                <span>Uploaded data will be encrypted using....</span>
              </div>
              <br />
              <Form>
                <FormGroup row>
                  <Label md={3}>Front ID</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Back ID</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Latest 3 months salary slip</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Latest 3 months bank statement of salary</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Latest EPF statement</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Lastest 6 month bank statement for self employed</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>SSM certificate</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>Other income proof</Label>
                  <Col md={9}>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                      This is some placeholder block-level help text for the above input.
                      It's a bit lighter and easily wraps to a new line.
                    </FormText>
                  </Col>
                </FormGroup>
              </Form>

    </LoadingOverlay>
    
      
    </>
  )
}

export default connect(({ loading }) => ({
  loading
}))(Step1);
