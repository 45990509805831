import React, { useEffect, useState } from "react";
import { Row, Col, Table, UncontrolledTooltip } from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const defaultData = {
  datasets: [
    {
      data: [10, 20, 30],
    },
  ],

  // These labels appear in the legend and in the tooltips when hovering different arcs
  labels: ["Red", "Yellow", "Blue"],
};

const Financial = (props) => {
  const { selectedBank, calculatorResult } = props;
  const [data, setData] = useState(defaultData);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    if (selectedBank) {
      const {
        electricity,
        water,
        internet,
        managementFee,
        repairMaintenanceReserve,
        sewerage,
      } = selectedBank.averageUtility;

      const tempRemaining =
        selectedBank.totalIncome -
        // selectedBank.currentDebtOutstanding -
        selectedBank.airumaIndex.totalMonthlyExpenses;
        // selectedBank.monthlyPayment; // - monthly payment has been included in total monthly expeses calculation
      
      setRemaining(tempRemaining);

      const datasets = [
        {
          data: [
            // tempRemaining,
            electricity,
            water,
            internet,
            managementFee,
            repairMaintenanceReserve,
            sewerage,
            selectedBank.monthlyPayment
          ],
          backgroundColor: [
            // "rgb(159, 249, 224)",
            "#EDB53A",
            "#5E7641",
            "#3252E4",
            "#B64C4D",
            "#941206",
            "#5D408C",
            "rgba(240, 89, 69)",
          ],
        },
      ];

      const labels = [
        // "Balance",
        "Electricity",
        "Water",
        "Internet",
        "Management fee",
        "Repair & maintenance reserve",
        "Sewerage",
        "Installment"
      ];

      setData({
        datasets,
        labels,
      });
    }
  }, [selectedBank]);

  return (
    <Row>
      <Col md="8" xs="12">
        <p className="calculation-tab-description">
          Owning a house is not just about the monthly installments. AIRUMA
          breaks down the general fees that you may need to bear every month and
          calculates your balance cash after deducting the monthly expenses.
        </p>
        <Table bordered>
          <tbody>
            <tr>
              <th>Estimated installment amount</th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  value={selectedBank && selectedBank.monthlyPayment}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr>
              <th>Estimated management fee</th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  decimalScale={2}
                  value={
                    selectedBank && selectedBank.averageUtility.managementFee
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr>
              <th>
                Estimated utilities fee &nbsp;
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  id="estimatedUtilitiesFee"
                />
                <UncontrolledTooltip target="estimatedUtilitiesFee">
                  Estimated amount of utilities to be paid per month. This
                  includes water, electricity and sewerage fees
                </UncontrolledTooltip>
              </th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  value={
                    selectedBank && selectedBank.averageUtility.totalUtilities
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            {/* <tr>
              <th>
                Estimated electricity bill (
                {selectedBank && selectedBank.propertyTitle})
              </th>
              <td>{selectedBank && selectedBank.averageUtility.electricity}</td>
            </tr>
            <tr>
              <th>
                Estimated water bill ({selectedBank && selectedBank.propertyTitle})
              </th>
              <td>{selectedBank && selectedBank.averageUtility.water}</td>
            </tr>
            <tr>
              <th>Estimated internet bill</th>
              <td>{selectedBank && selectedBank.averageUtility.internet}</td>
            </tr> */}
            <tr>
              <th>
                Repair & maintenance reserve &nbsp;
                <FontAwesomeIcon icon={faInfoCircle} id="repair" />
                <UncontrolledTooltip target="repair">
                  1% of property price divided by 12 months. This will act as
                  the fund for furniture replacement, house maintenanceand etc.
                </UncontrolledTooltip>
              </th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  decimalScale={2}
                  value={
                    selectedBank &&
                    selectedBank.averageUtility.repairMaintenanceReserve
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr>
              <th>Current commitment (based on input)</th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  decimalScale={2}
                  value={
                    selectedBank && selectedBank.currentDebtOutstanding
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            {/* <tr>
              <th>Sewerage fee</th>
              <td>{selectedBank && selectedBank.averageUtility.sewerage}</td>
            </tr> */}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: 'rgba(240, 89, 69, 0.5)'}}>
              <th>Estimated monthly expenses</th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  decimalScale={2}
                  className="calculator-amount"
                  value={
                    selectedBank &&
                    selectedBank.airumaIndex.totalMonthlyExpenses
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
            <tr style={{ backgroundColor: '#BFBFBF'}}>
              <th>Balance</th>
              <td>
                <span className="calculator-currency">
                  {calculatorResult.currency}{" "}
                </span>
                <NumberFormat
                  className="calculator-amount"
                  decimalScale={2}
                  value={remaining}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Col>
      <Col md="4" xs="12">
        {/* <Doughnut data={data} /> */}
        {/* <Card>
        <CardBody style={{ textAlign: 'center'}}>
          <h4>AIRUMA Stress Index&trade;</h4>
          <span>
            <h3>{selectedBank && Math.round(selectedBank.airumaIndex.airumaStressLevel * 100) / 100}</h3>
          </span>
        </CardBody>
      </Card> */}
      </Col>
    </Row>
  );
};

export default Financial;
