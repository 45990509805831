import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faShower,
  faBuilding,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import ResultBasicDetail from './BlogDetail';
import "./style.css";
import defaultImg from '../../../resources/img/logo192.png'

const ResultCard = (props) => {
  const { property, dispatch } = props;
  const history = useHistory();

  const {
    lat,
    long,
    name,
    state,
    description,
    startingPrice,
    buildingType,
    propertyType,
    bedroomCount,
    bathroomCount,
    unitArea,
    areaMeasurementUnit,
    totalStorey,
    locatedStorey,
    tenure, //freehold
    propertyTitle, // individual, strata, master
    estimatedmonthlyPayment,
    currency,
    imgSrc
  } = property;

  const getStoreyRangeText = (located, total) => {
    const result = located/total
    let rangeText
    if (result < 25) {
      rangeText = 'low'
    } else if (result < 50) {
      rangeText = 'lower high'
    } else if (result < 75) {
      rangeText = 'higher low'
    } else {
      rangeText = 'high'
    }
    return rangeText
  }

  const goDetailPage = (id) => {
    dispatch.detail.fetchListingDetail(id)
    history.push('/detail')
  }

  const defaultTextBody = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae sapien efficitur, tempor felis posuere, mollis tellus. Nullam libero neque, egestas vitae magna quis, porttitor egestas tellus. .`
  const defaultTextTitle = `Lorem ipsum dolor sit amet`

  return (
    <div className="resultCard">
      <div style={{ width: "20%", textAlign: 'center', borderRight: 'solid 1px rgba(0,0,0,.125)' }}>
        <img className="blogListImg" src={imgSrc === '' || !imgSrc ? defaultImg : imgSrc} alt="Propety"/>
      </div>
      <div style={{ width: "80%", margin: "10px" }}>
        <span onClick={() => goDetailPage(property.id)} className="blogListTitleFont">
          {defaultTextTitle}
        </span>
        <div>
          <span className="blogListBodyFont">
            {
              defaultTextBody
            }
          </span>
        </div>
      </div>
    </div>
  );
};

ResultCard.propTypes = {
  property: PropTypes.object,
};

ResultCard.defaultProps = {
  property: {
    id: 'abcde',
    lat: 1.2222,
    long: 2.2222,
    name: 'The Vyne',
    propertyType: 'condominium', // landed, service apertment
    buildingType: 'highrise', //landed
    state: 'Kuala Lumpur',
    description: 'Default location text',
    startingPrice: 1234039.00,
    bedroomCount: 1,
    bathroomCount: 1,
    unitArea: 1234,
    areaMeasurementUnit: 'sqft',
    totalStorey: 40,
    locatedStorey: 20,
    tenure: 'leasehold', //freehold
    propertyTitle: 'Commercial', // individual, strata, master
    estimatedmonthlyPayment: 1234.00,
    currency: 'RM',
    imgSrc: defaultImg,
  },
};

export default connect()(ResultCard);
