import React, { useState } from "react";
import {
  Input,
  Button,
  FormGroup,
  Col,
  Spinner,
  UncontrolledAlert,
  Row,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import axios from "axios";

import FormikReactStrapCustomInput from "../../../formikReactStrapCustomInput";
import FormikReactStrapInput from "../../../formikReactStrapInput";
import { ReportSchema } from "../../validation";

const GetReport = (props) => {
  const { calculator, selectedBank, chartConfig } = props;
  const {
    isMOTSelected,
    isSPALegalSelected,
    isLoanLegalSelected,
    isLoanStampDutySelected,
    isValuationSelected,
    isConsentFeeSelected,
    isFixtureSelected,
  } = props;
  const { calculatorResult } = calculator;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const handleSubmit = async (values) => {
    const payload = {
      email: values.email,
      isSubscribeNewsletter: values.isSubscribeNewsletter,
      report: calculatorResult,
      selectedUpfrontCost: {
        isMOTSelected,
        isSPALegalSelected,
        isLoanLegalSelected,
        isLoanStampDutySelected,
        isValuationSelected,
        isConsentFeeSelected,
        isFixtureSelected,
      },
      selectedBank,
      chartConfig,
    };
    try {
      setError(undefined);
      setSuccess(undefined);
      setIsSubmitting(true);
      await axios.post("/services/sendReport", payload);
      setSuccess("Report has been sent successfully.");
    } catch (err) {
      setError(
        "Our trained monkey is rescuing the fire, please try again later."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getButtonText = () => {
    let text = "Get your report now!";
    if (isSubmitting) {
      text = <Spinner />;
    } else if (success) {
      text = "Report sent";
    }
    return text;
  };

  return (
    <div style={{ height: "100%" }}>
      <Row>
        <Col md="12">
          {error ? (
            <UncontrolledAlert color="danger">{error}</UncontrolledAlert>
          ) : undefined}
          {success ? (
            <UncontrolledAlert color="success" className="airuma-alert-success">
              <div style={{ textAlign: "center" }}>{success}</div>
              <div style={{ textAlign: "center" }}>
                Please help us by completing <a href="https://forms.gle/CSQmBKNS27SWZm5K8" target="_blank" rel="noreferrer">this survey</a>
              </div>
            </UncontrolledAlert>
          ) : undefined}
          <p>Get your report for free!</p>

          <Formik
            // innerRef={formRef}
            validationSchema={ReportSchema}
            initialValues={{
              email: "",
              isSubscribeNewsletter: true,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit, isValid, submitForm, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup row>
                  <Col xs="12" md="5">
                    <Field
                      name="email"
                      id="email"
                      placeholder="Type your email"
                      component={FormikReactStrapInput}
                      type="email"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="isSubscribeNewsletter"
                    id="isSubscribeNewsletter"
                    component={FormikReactStrapCustomInput}
                    type="checkbox"
                    label="Subscribe to our newsletter for latest info. 0% spam, 100% useful."
                  />
                  {/* <Label check>
                 <Input type="checkbox" /> 
               </Label> */}
                </FormGroup>
                <div style={{ marginTop: "2%",  }}>
                  <Button
                    disabled={isSubmitting || (success !== "" && !!success)}
                    type="submit"
                    className="airuma-btn-primary"
                  >
                    {getButtonText()}
                  </Button>
                  <br />
                  <br />
                  <div style={{ textAlign: "",  lineHeight: '25pt',  }}>
                    <a href="https://forms.gle/CSQmBKNS27SWZm5K8" target="_blank" rel="noreferrer">Help us to take a quick survey!</a>
                  </div>
                  {/* <Button color="link" >
                    Help us to take this quick survey!
                  </Button> */}
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        {/* <Col md="3" className="airuma-border-left-primary">
          <div style={{ position: 'relative', top: '40%', textAlign: 'center'}}>
            Please help us by completing 
            <br />
            <a href="#">this survey</a>

          </div>
        </Col> */}
      </Row>
      {/* <Row>
        <Col>
          <div
            style={{ position: "relative", top: "40%", textAlign: "center" }}
          >
            <Button block className="airuma-btn-primary-borderless">
              Help us to take this quick survey
            </Button>
          </div>
        </Col>
      </Row> */}
    </div>
  );
};

export default connect(({ loading, calculator }) => ({
  loading,
  calculator,
}))(GetReport);
