import React from "react";
import { Table, Badge, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import NumberFormat from "react-number-format";

const Banks = (props) => {
  const { calculatorResult, onRadioChange, selectedBank } = props;

  const getChangeBadge = (chance) => {
    let color;
    switch (chance) {
      case "IMPOSSIBLE":
        color = "danger";
        break;
      case "NEAR":
        color = "warning";
        break;
      default:
        color = "success";
    }
    return <Badge color={color}>{chance}</Badge>;
  };

  return (
    <div>
      <p className="calculation-tab-description">
        Based on your profile, these are the Banks that might fulfill your dream
        home.
      </p>
      {selectedBank && selectedBank.chances !== "HIGH" ? (
        <Alert color="warning">
          ⚠️Your current monthly commitment is high. Consider reducing your
          expenses or increasing your income to improve the chances of mortgage
          loan approval from banks.
        </Alert>
      ) : undefined}
      <Table responsive>
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th colSpan="3">Banks</th>
            <th>Eligibility</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          {calculatorResult &&
            calculatorResult.banks &&
            calculatorResult.banks.map((e) => (
              // <li>
              //   {e.bankName} - {e.bestRate}% - {e.chances}
              // </li>

              <tr key={e.bankName} onClick={() => onRadioChange(e)}>
                <td style={{ textAlign: "center" }} className="loan-bank-col">
                  {selectedBank && selectedBank.bankName === e.bankName ? (
                    <FontAwesomeIcon icon={faCheckSquare} />
                  ) : (
                    <FontAwesomeIcon icon={faSquare} />
                  )}
                </td>
                <td style={{ width: "10%" }} className="loan-bank-col">
                  <img
                    className="loan-bank-logo"
                    src={e.bankLogo}
                    alt="Bank logo"
                  />
                </td>
                <td className="loan-bank-col">
                  <span className="loan-font-bold">{e.bankName}</span>
                </td>
                <td className="loan-bank-col" style={{ textAlign: 'center'}}>
                  <span className="loan-font-bold">
                    {getChangeBadge(e.chances)}
                  </span>
                </td>
                <td className="loan-bank-col">
                  Best rate:{" "}
                  <span className="loan-font-bold">{e.bestRate} %</span>
                  <br />
                  Monthly payment:{" "}
                  <span className="calculator-currency">
                    {calculatorResult.currency}
                  </span>
                  &nbsp;
                  <NumberFormat
                    className="calculator-amount"
                    value={e.monthlyPayment}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
            ))}
        </tbody>
        {/* 
              <FormGroup check key={e.bankName}>
                <Input
                  id={e.bankName}
                  type="radio"
                  name="radio"
                  checked={
                    e.bankName === selectedBank
                      ? selectedBank.bankName
                      : undefined
                  }
                  value={e.bankName}
                  onChange={(ele) => onRadioChange(ele, e)}
                />
                <Label check for={e.bankName}>
                  {e.bankName} - {e.bestRate}%
                </Label>
              </FormGroup> */}
      </Table>
    </div>
  );
};
export default Banks;
