export default {
  name: "booking",
  state: {
    unitDetail: {
      lat: 1.2222,
      long: 2.2222,
      name: 'The Vyne',
      state: 'Kuala Lumpur',
      description: 'Default location text',
      propertyType: 'condominium', // landed, service apertment
      startingPrice: 1234039.00,
      bedroom: 1,
      bathroom: 1,
      unitArea: 1234,
      areaMeasurementUnit: 'sqft', //to be change to unitAreaUOM
      totalStorey: 40,
      locatedStorey: 20,
      locatedUnitNumber: 1,
      tenure: 'leasehold', //freehold
      propertyTitle: 'Commercial', // individual, strata, master
      estimatedmonthlyPayment: 1234.00,
      currency: 'RM'
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload;
    },
    saveSelectedUnitDetail(state, payload) {
      return {
        ...state,
        unitDetail: payload
      }
    },
    clearSelectedUnitDetail(state) {
      return {
        ...state,
        unitDetail: undefined
      }
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions.
    async checkCCRIS(payload) {
      console.log(`search for payload ${payload}`)
      await new Promise((resolve) => setTimeout(resolve, 1500));
    },
    async fetchListingDetail(payload) {
      console.log(`search for payload ${payload}`)
      await new Promise((resolve) => setTimeout(resolve, 1500));
      // dispatch.listing.saveListings([
      //   'abcdfgh'
      // ])
    },
    async incrementAsync(payload, rootState) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch.listing.increment(payload);
    },
  }),
};
