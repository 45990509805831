import React from "react";

const TOU = () => {
  return (
    <div>
      <h3>TERMS OF USE</h3>

      <ul>
        <li>
          AIRUMA is a brand owned by LEXTREE PLT, a limited liability
          partnership registered in Malaysia under Limited Liability Partnership
          Act 2012 (‘LEXTREE’ / ‘We’).
        </li>
        <li>
          By accessing, viewing and/or using this website at www.airuma.com.my
          (‘website’), you expressly agree and acknowledge that you have
          understood, accepted and are bound by the terms and conditions of use
          (‘Terms’) prescribed by LEXTREE set out as below. If you do not wish
          to be bound by the Terms of this website, please stop using this
          website immediately.
        </li>
        <li>
          The information, materials and content on this website including but
          not limited to these terms and conditions of use may be updated from
          time to time without prior notice. If you continue to use the website
          after new Terms are updated, you are deemed to have accepted to be
          bound by the new Terms.
        </li>
        <li>
          The information, materials and content on this website are made
          available and are intended strictly and solely for general information
          purposes. They do not in any way constitute, are not intended to
          constitute, or relied upon as professional or financial advice or the
          provision of professional services to any person or legal entity.
        </li>
        <li>
          The information, materials and content on this website are made
          available on an “as-is” basis. No warranty or representation of
          whatever nature, whether express or implied or otherwise, is given in
          respect of such information, materials, or content.
        </li>
        <li>
          While reasonable effort has been taken to ensure the accuracy of any
          information, materials or content in this website, we do not assume
          any responsibility for their accuracy or continuing accuracy, or for
          any consequences of relying on it in any manner whatsoever.
        </li>
        <li>
          We expressly disclaim any liability or responsibility for any loss or
          damage of whatever nature, whether direct, indirect, consequential or
          otherwise, which arises or may arise from the use of or reliance on
          any information, materials or content contained in this website.
        </li>
        <li>
          While every reasonable effort has been taken to ensure that the
          information, materials and content on this website are free from
          viruses, trojans, malicious codes, or other materials that may damage,
          disrupt or otherwise interfere with the operation of any computer
          system(s), we expressly disclaim any liability or responsibility
          whatsoever for any loss or damage of whatever nature caused by,
          arising from or otherwise attributable to any damage, disruption to or
          interference to any computer system(s) caused by, arising from or
          otherwise attributable to the use of this website.
        </li>
        <li>
          Copyright © by LEXTREE PLT. All rights reserved. LEXTREE is the owner
          or the licensee of all intellectual property rights of or in relation
          to the information, materials, content, layout, design, text,
          photographs, electronic media, graphics and trademarks contained on
          this website (except where credits are given) and we reserve all our
          rights in respect thereof. You may download and print out any part of
          this website for your own personal or non-commercial use. Any other
          reproduction or retransmission of the contents of this website without
          our prior written consent is prohibited.
        </li>
        <li>
          These Terms shall be governed by and interpreted in accordance with
          law of Malaysia and we and you each submit irrevocably to the
          exclusive jurisdiction of the High Court of Malaya.
        </li>
      </ul>
    </div>
  );
};

export default TOU;
