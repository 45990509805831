import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";

import Accordian from "../../Accordian";
import Banks from "./ResultTabs/Banks";
import AIRUMAAnalysis from "./ResultTabs/AirumaAnalysis";
import CostOfPossesion from "./ResultTabs/MontlyExpenses";
import CostUpfront from "./ResultTabs/CostUpfront";
import Financial from "./ResultTabs/PersonalFinancial";
import ROI from "./ResultTabs/ROI";
import GetReport from "./ResultTabs/GetReport";

const Result = (props) => {
  const { calculator, activeTab, setActiveTab } = props;
  const { calculatorResult } = calculator;

  const [selectedBank, setSelectedBank] = useState();
  const [chartConfig, setChartConfig] = useState();

  const [isMOTSelected, setIsMOTSelected] = useState(true);
  const [isSPALegalSelected, setIsSPALegalSelected] = useState(true);
  const [isLoanLegalSelected, setIsLoanLegalSelected] = useState(true);
  const [isLoanStampDutySelected, setIsLoanStampDutySelected] = useState(true);
  const [isValuationSelected, setIsValuationSelected] = useState(true);
  const [isConsentFeeSelected, setIsConsentFeeSelected] = useState(true);
  const [isFixtureSelected, setIsFixtureSelected] = useState(true);

  const onRadioChange = (bank) => {
    setSelectedBank(bank);
  };

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (
      calculatorResult &&
      calculatorResult.banks &&
      calculatorResult.banks.length > 0
    )
      setSelectedBank(calculatorResult.banks[0]);
  }, [calculatorResult]);

  return (
    <div>
      <span className="calculator-result-title">Summary</span>
      <br />
      <div className="calculator-result-subtitle">
        <span className="selected-bank-title">Selected bank: </span>
        <span className="selected-bank-value">
          {selectedBank && selectedBank.bankName}
        </span>
      </div>

      <br />

      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === "BANKS"}
            className={classnames({ active: activeTab === "BANKS" })}
            onClick={() => {
              toggle("BANKS");
            }}
          >
            Eligible Banks
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "COST_OF_POSESSION"}
            className={classnames({
              active: activeTab === "COST_OF_POSESSION",
            })}
            onClick={() => {
              toggle("COST_OF_POSESSION");
            }}
          >
            Monthly Expenses
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "CASH_UPFRONT" })}
            onClick={() => {
              toggle("CASH_UPFRONT");
            }}
          >
            Affordability
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            active={activeTab === "CASH_UPFRONT"}
            className={classnames({ active: activeTab === "CASH_UPFRONT" })}
            onClick={() => {
              toggle("CASH_UPFRONT");
            }}
          >
            Upfront Fees
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "FINANCIAL_REPORT" })}
            onClick={() => {
              toggle("FINANCIAL_REPORT");
            }}
          >
            AIRUMA Insights
          </NavLink>
        </NavItem>
        <NavItem className="get-your-report-tab">
          <NavLink
            active={activeTab === "GET_FINANCIAL_REPORT"}
            className={classnames({
              active: activeTab === "GET_FINANCIAL_REPORT",
            })}
            onClick={() => {
              toggle("GET_FINANCIAL_REPORT");
            }}
          >
            <span className="get-your-report-tab-title">Get Your Report</span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "ROI" })}
            onClick={() => {
              toggle("ROI");
            }}
          >
            ROI
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab} style={{ padding: "18px", minHeight: '400px' }}>
        <TabPane tabId="BANKS">
          <Banks
            calculatorResult={calculatorResult}
            selectedBank={selectedBank}
            onRadioChange={onRadioChange}
          />
        </TabPane>
        <TabPane tabId="COST_OF_POSESSION">
          <CostOfPossesion
            calculatorResult={calculatorResult}
            selectedBank={selectedBank}
          />
        </TabPane>
        {/* <TabPane tabId="CASH_UPFRONT">
          <AIRUMAAnalysis calculatorResult={calculatorResult} />
        </TabPane> */}
        <TabPane tabId="CASH_UPFRONT">
          <CostUpfront
            calculatorResult={calculatorResult}
            selectedBank={selectedBank}
            isMOTSelected={isMOTSelected}
            setIsMOTSelected={setIsMOTSelected}
            isSPALegalSelected={isSPALegalSelected}
            setIsSPALegalSelected={setIsSPALegalSelected}
            isLoanLegalSelected={isLoanLegalSelected}
            setIsLoanLegalSelected={setIsLoanLegalSelected}
            isLoanStampDutySelected={isLoanStampDutySelected}
            setIsLoanStampDutySelected={setIsLoanStampDutySelected}
            isValuationSelected={isValuationSelected}
            setIsValuationSelected={setIsValuationSelected}
            isConsentFeeSelected={isConsentFeeSelected}
            setIsConsentFeeSelected={setIsConsentFeeSelected}
            isFixtureSelected={isFixtureSelected}
            setIsFixtureSelected={setIsFixtureSelected}
          />
        </TabPane>
        <TabPane tabId="FINANCIAL_REPORT">
          <Financial
            calculatorResult={calculatorResult}
            selectedBank={selectedBank}
            setChartConfig={setChartConfig}
          />
        </TabPane>
        <TabPane tabId="GET_FINANCIAL_REPORT">
          <GetReport
            selectedBank={selectedBank}
            chartConfig={chartConfig}
            isMOTSelected={isMOTSelected}
            isSPALegalSelected={isSPALegalSelected}
            isLoanLegalSelected={isLoanLegalSelected}
            isLoanStampDutySelected={isLoanStampDutySelected}
            isValuationSelected={isValuationSelected}
            isConsentFeeSelected={isConsentFeeSelected}
            isFixtureSelected={isFixtureSelected}
          />
        </TabPane>
        <TabPane tabId="ROI">
          <ROI calculatorResult={calculatorResult} />
        </TabPane>
      </TabContent>
      <div className="legal-disclaimer-container">
        <p className="legal-disclaimer">
          Disclaimer: The information and materials contained in this website
          are for general information purposes only and does not constitute any
          professional advice whatsoever. If you require any professional
          advice, kindly contact your professional consultants / specialists
          that may assist you. While substantial efforts had been expended to
          ensure that the information are as accurate as possible, this website
          does not guarantee the accuracy of the information and accepts no
          liability for any inaccuracies or omissions. The above also does not
          constitute an offer for credit nor does it guarantee that the credit
          facility applied for will be approved. All applications for credit
          facility are subject to credit evaluation and approval by the
          institutions offering such credit facilities and this website shall
          not be liable for any actions or omissions by such institutions
          offering credit facilities.
        </p>
      </div>
    </div>
  );
};
export default connect(({ loading, calculator }) => ({
  loading,
  calculator,
}))(Result);
