import React from 'react';
import Calculator from '../../components/Calculator';
import { Row, Col } from 'reactstrap'

const CalculatorPage = (props) => {
  return (
    
      <Row>
        <Col xs="12" md="12" lg={{ offset: 1, size: 10 }}>
          <Calculator />
        </Col>
      </Row>
      
   
  )
}

export default CalculatorPage;
