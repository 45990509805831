import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const texts = ['Assembling water pipe', 'Leaking check', 'Getting calculator', 'Watching TV', 'Signing SPA']

const CreditChecking = () => {
  const [ran, setRan] = useState(Math.floor(Math.random() * 5))
  useEffect(() => {
    let timer = setTimeout(
      () => {
        setRan(Math.floor(Math.random() * 5))}, 
      500
    );
    return () => {
      clearTimeout(timer);
    };
  })
  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <FontAwesomeIcon icon={faSpinner} size="5x" pulse />
      </div>
      <br />
      <div>
        {texts[ran]}
      </div>
    </div>
  )
}

export default CreditChecking