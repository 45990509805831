import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import PrivacyComponent from '../../../Polices/Privacy'
const PrivacyModal = (props) => {
  const {isOpen, toggle} = props
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Privacy policy
      </ModalHeader>
      <ModalBody>
        <PrivacyComponent />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PrivacyModal;
