import React from 'react';
import { getIn } from 'formik';

import { CustomInput, FormFeedback } from 'reactstrap';

// as per https://codebrains.io/build-react-forms-validation-formik-reactstrap/

const CustomInputForm = ({ field, form: { touched, errors }, ...props }) => {
  return (
  <>
    <CustomInput
      invalid={!!(getIn(touched, field.name) && getIn(errors, field.name))}
      {...field}
      {...props}
    >
      {!!(getIn(touched, field.name) && getIn(errors, field.name)) && <FormFeedback>{getIn(errors, field.name)}</FormFeedback>}
    </CustomInput>
  </>
)};

export default CustomInputForm;
