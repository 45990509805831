import React, { useCallback, useEffect, useRef } from "react";
import { loadBingApi, Microsoft } from "./BingMapLoader";

export default (props) => {
  const mapRef = useRef();

  const initMap = useCallback(() => {
    const map = new Microsoft.Maps.Map(mapRef.current);
    if (props.mapOptions) {
      map.setOptions(props.mapOptions);
    }
    return map;
  }, [])

  useEffect(() => {
    loadBingApi().then(() => {
      initMap();
    });
  }, [initMap])


  return (
    <div ref={mapRef} className="map" />
  )
}
