import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardFooter,
  CardHeader,
  UncontrolledAlert
} from "reactstrap";
import { connect } from "react-redux";

import BasicInfo from "./components/BasicInfo";
import Checking from "./components/Checking";
import ResultPage from "./components/Result";

import PrivacyModal from "./components/Modals/privacy";
import TOCModal from "./components/Modals/toc";

import "./index.scss";

const LoanCalculator = (props) => {
  const { loading, dispatch, calculator } = props;
  const { hasError } = calculator;
  const [step, setStep] = useState(1);
  const [error, setError] = useState('')
  const [component, setComponent] = useState(<BasicInfo />);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTOUModalOpen, setIsTOUModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("BANKS");

  const basicFormRef = useRef();

  const nextTab = () => {
    switch (activeTab) {
      case "BANKS":
        setActiveTab("COST_OF_POSESSION");
        break;
      case "COST_OF_POSESSION":
        setActiveTab("CASH_UPFRONT");
        break;
      case "CASH_UPFRONT":
        setActiveTab("FINANCIAL_REPORT");
        break;
      case "FINANCIAL_REPORT":
        setActiveTab("GET_FINANCIAL_REPORT");
        break;
      case "GET_FINANCIAL_REPORT":
        setActiveTab("GET_FINANCIAL_REPORT");
        break;
      default:
        setActiveTab("BANKS");
    }
  };

  const prevTab = () => {
    switch (activeTab) {
      case "BANKS":
        setActiveTab("BANKS");
        break;
      case "COST_OF_POSESSION":
        setActiveTab("BANKS");
        break;
      case "CASH_UPFRONT":
        setActiveTab("COST_OF_POSESSION");
        break;
      case "FINANCIAL_REPORT":
        setActiveTab("CASH_UPFRONT");
        break;
      case "GET_FINANCIAL_REPORT":
        setActiveTab("FINANCIAL_REPORT");
        break;
      default:
        setActiveTab("BANKS");
    }
  };

  const togglePrivacyModal = useCallback(() => {
    setIsPrivacyModalOpen(!isPrivacyModalOpen)
  }, [setIsPrivacyModalOpen, isPrivacyModalOpen])

  const toggleTOUModal = useCallback(() => {
    setIsTOUModalOpen(!isTOUModalOpen)
  }, [setIsTOUModalOpen, isTOUModalOpen])

  useEffect(() => {
    switch (step) {
      case 2:
        if (loading.effects.calculator.calculateLoanAmount) {
          setError('')
          setComponent(<Checking />);
        } else if (hasError) {
          setError('Our monkey army is finding the rotten banana. Please try again later.')
          setComponent(<BasicInfo formRef={basicFormRef} togglePrivacyModal={togglePrivacyModal} toggleTOUModal={toggleTOUModal} />);
          setStep(1)
        } else
         {
          setComponent(
            <ResultPage activeTab={activeTab} setActiveTab={setActiveTab} />
          );
        }
        break;
      default:
        setComponent(<BasicInfo formRef={basicFormRef} togglePrivacyModal={togglePrivacyModal} toggleTOUModal={toggleTOUModal} />);
    }
  }, [step, setComponent, basicFormRef, loading, activeTab, setActiveTab, togglePrivacyModal, toggleTOUModal, setError, hasError]);

  const goToNext = async () => {
    if (step < 2) {
      if (step === 1 && basicFormRef.current.isValid) {
        await basicFormRef.current.submitForm();
        if (basicFormRef.current.isValid) {
          setStep(step + 1);
        }
      } else if (step > 1) {
        setStep(step + 1);
      }
    }
  };

  const goToPrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // const startAgainAndReset = () => {
  //   dispatch.calculator.resetInput();
  //   setStep(1);
  // };
 

  return (
    <div>
      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        toggle={togglePrivacyModal}
      />
      <TOCModal
        isOpen={isTOUModalOpen}
        toggle={toggleTOUModal}
      />
      <div className="airuma-page-title">AIRUMA Property Calculator</div>
      <Card className="airuma-card airuma-border-card-primary">

        <CardBody style={{ minHeight: "600px" }}>
          { error !== '' ? (
            <UncontrolledAlert color="danger">
              {error}
            </UncontrolledAlert>
          ): undefined}
          {component}
        </CardBody>
        <CardFooter>
          {step > 1 ? (
            <Button
              onClick={goToPrev}
              className="airuma-btn-info airuma-btn-outline calculator-button"
            >
              Back to form
            </Button>
          ) : undefined}
          {step < 2 ? (
            <Button
              onClick={goToNext}
              className="airuma-btn-primary calculator-button"
            >
              Next
            </Button>
          ) : undefined}
          {step === 2 ? (
            <>
              {activeTab !== "BANKS" ? (
                <Button
                  className="airuma-btn-primary calculator-button"
                  onClick={prevTab}
                >
                  Back
                </Button>
              ) : undefined}
              {activeTab !== "GET_FINANCIAL_REPORT" ? (
                <Button
                  className="airuma-btn-primary calculator-button"
                  onClick={nextTab}
                >
                  Next
                </Button>
              ) : undefined}
              {activeTab !== "GET_FINANCIAL_REPORT" ? (
                <Button
                  className="airuma-btn-primary calculator-button"
                  onClick={() => setActiveTab("GET_FINANCIAL_REPORT")}
                >
                  Generate report
                </Button>
              ) : undefined}

              {/* <Button className="calculator-button">Contact us</Button> */}
              {/* <Button className="calculator-button">Generate report</Button> */}
            </>
          ) : undefined}
        </CardFooter>
      </Card>
    </div>
  );
};

export default connect(({ loading, calculator }) => ({
  loading, calculator
}))(LoanCalculator);
