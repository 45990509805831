import React from 'react'
import { Modal, ModalFooter, ModalBody } from 'reactstrap';

import Calculator from '../../../components/Calculator'

const CalculatorModal = (props) => {
  const { toggleModal, isModalOpen } = props
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
      <ModalBody>
        <Calculator />
      </ModalBody>
    </Modal>
  )
}

export default CalculatorModal;
