import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Input, Button, CardImg } from "reactstrap";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'

import "./style.scss";

import ImageSlides from './components/ImagesSlides'
import CalculatorModal from './components/CalculatorModal'

const HomePage = (props) => {
  const [searchText, setSearchText] = useState('');
  const [isCalculatorModalOpen, setIsCalculatorModalOpen] = useState(false);

  const history = useHistory();

  const toggleCalculatorModalOpen = () => {
    setIsCalculatorModalOpen(!isCalculatorModalOpen)
  }

  const goToCalculator = () => {
    history.push('/calculator')
  }

  const onChangeSearchField = (e) => {
    setSearchText(e.target.value);
  };

  const getListing = () => {
    props.dispatch.listing.fetchListing(searchText);
    history.push('/listing')
  }

  return (
    <div>
      <div className="banner">
        <img
          src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/brewster-mcleod-architects-1486154143.jpg?crop=1.00xw:1.00xh;0,0&resize=980:*"
          alt="Banner"
          style={{ width: 'auto', height: '100%'}}
        />
      </div>
      <CalculatorModal isModalOpen={isCalculatorModalOpen} toggleModal={toggleCalculatorModalOpen} />
      <div className="homeCard">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }}>
            <Card className="homeCardBox">
              <CardBody>
                <Row style={{ paddingBottom: "18px" }}>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Your dream home, start here.</span>
                  </Col>
                </Row>
                <Input value={searchText} onChange={onChangeSearchField} />
                <Row style={{ padding: "28px" }}>
                  <Col xs="12" style={{ textAlign: "center" }}>
                    <Button className="airuma-btn-primary" onClick={getListing}>
                      Find my property
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button color="primary" outline>
                      I fell lucky
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="homePageElements">
        <Row>
          <Col xs={{ size: 4, offset: 1}}>
            <Card>
              <CardBody>
                <ImageSlides />
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <CardImg
                top
                style={{ height: '210px', width: 'auto' }}
                src="https://review.chinabrands.com/chinabrands/seo/image/20171111/blog%201.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <Button color="link">
                  Click here for read of the day!
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card>
              <CardImg
                top
                style={{ height: '210px', width: 'auto' }}
                src="https://review.chinabrands.com/chinabrands/seo/image/20171111/blog%201.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <Button color="link">
                  Review of PJ condos
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="floating-menu">
          <Button outline size="lg" onClick={goToCalculator}>
            <FontAwesomeIcon icon={faCalculator} />
          </Button>
        </div>
      </div>

      
    </div>
  );
};

export default connect(
  ({ listing }) => ({ listing })
  // state=> ({

  // }),
  // dispatch => ({

  // })
)(HomePage);
