import React from "react";
import { Card, CardBody, Form, FormGroup, Input, Row, Col } from "reactstrap";

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const Filter = () => {
  return (
    <Card>
      <CardBody>
        <Form>
          <Row>
            <Col md="3">
              <Input type="select" name="select" id="exampleSelect">
                <option>Any state</option>
                <option>Kuala Lumpur</option>
              </Input>
            </Col>
            <Col md="3">
              <Input type="select" name="select" id="exampleSelect">
                <option>Any type</option>
                <option>Landed</option>
                <option>Condo</option>
              </Input>
            </Col>
            <Col md="3">
              <Input type="select" name="select" id="exampleSelect">
                <option>Any state</option>
                <option>2</option>
              </Input>
            </Col>
            <Col md="3">
            <Range />
            </Col>
          </Row>
          <FormGroup>
          
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Filter;
