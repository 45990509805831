import React from 'react';
import TOUComponent from '../../../components/Polices/TermsOfUse'


const TOU = () => {
  return (
    <div className="container">
     <TOUComponent />
    </div>
  )
}

export default TOU;
