import React from 'react';
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux'

const Step1 = (props) => {
  const { loading } = props;

  return (
    <>
    <LoadingOverlay
      active={loading.effects.listing.checkCCRIS}
      spinner
      text='CCRIS Checking...'
    >

      <p>
      <div style={{ textAlign: 'center'}}>
          <span>Your application has passed the initial stage. How would you want to proceed?</span>
        </div>
        <br />
        <br />
        <div style={{ textAlign: 'center'}}>
          <Button>Book now</Button>
          <Button style={{ marginLeft: '30px'}}>Talk to agent</Button>
        </div>
      </p>  
      <p>
      <div style={{ textAlign: 'center'}}>
        <span>Our initial finding discovered your financial status is not suit this property. Do you want to contact our agent?</span>
      </div>
      <br />
      <br />
      <div style={{ textAlign: 'center'}}>
        <Button style={{ marginLeft: '30px'}}>Yes, I want talk to agent</Button>
      </div>
      </p>
    </LoadingOverlay>
    
      
    </>
  )
}

export default connect(({ loading }) => ({
  loading
}))(Step1);
