import React from "react";
import { Card, CardBody, CardText, CardLink } from "reactstrap";

import testImg from "../../../resources/img/logo192.png";

const BlogCard = () => {
  return (
    <Card>
      <CardBody>
        {/* <CardTitle tag="h5">Card title</CardTitle> */}
        {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
      </CardBody>
      <img
        src={testImg}
        alt="Card image cap"
        style={{ height: "120px", width: "100%" }}
      />
      <CardBody>
        {/* <CardText>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </CardText> */}
        <CardLink href="#">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </CardLink>
        {/* <CardLink href="#">Another Link</CardLink> */}
      </CardBody>
    </Card>
  );
};
export default BlogCard;
