import axios from 'axios';

// Setup defaults
const setupAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_AIRUMA_API;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Cache-Control'] = 'no-store';
  axios.defaults.headers.common['Pragma'] = 'no-cache';

  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      config.withCredentials = true;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};

setupAxios();

export default setupAxios;
