import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

import {
  Col,
  FormGroup,
  Button,
  Label,
  Card,
  CardGroup,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { CalculatorSchema } from "../validation";
// import '../index.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import FormikReactStrapInput from "../../formikReactStrapInput";
import FormikReactStrapCustomInput from "../../formikReactStrapCustomInput";

const BasicInfoForm = (props) => {
  const { calculator, dispatch, app } = props;
  const { togglePrivacyModal, toggleTOUModal } = props;
  const { calculatorFormValue } = calculator;
  const { currency } = app;
  const { formRef } = props;

  const handleSubmit = (values, actions) => {
    dispatch.calculator.calculateLoanAmount(values);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        ...calculatorFormValue,
      }}
      validationSchema={CalculatorSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isValid, submitForm, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <CardGroup>
            <Card className="card-border-right">
              <CardHeader className="calculator-card-header">
                Your property
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="propertyPrice">Property SPA price</Label>
                  <Field
                    onChange={(e) => {
                      const propertyPrice = e.target.value;
                      const downPayment = propertyPrice * 0.1;
                      const loanAmount = propertyPrice - downPayment;
                      setFieldValue("propertyPrice", e.target.value);
                      setFieldValue("downPayment", downPayment);
                      setFieldValue("loanAmount", loanAmount);
                    }}
                    name="propertyPrice"
                    id="totalLoan"
                    placeholder="New property price"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                <FormGroup row>
                  <Col md="12" lg="6">
                    <Label for="downPayment">Down payment</Label>
                    <Field
                      onChange={(e) => {
                        const loanAmount =
                          values["propertyPrice"] - parseInt(e.target.value, 10);
                        setFieldValue("downPayment", parseInt(e.target.value, 10));
                        setFieldValue("loanAmount", loanAmount);
                      }}
                      name="downPayment"
                      id="downPayment"
                      placeholder="Down payment"
                      component={FormikReactStrapInput}
                      type="number"
                    />
                  </Col>
                  <Col md="12" lg="6">
                    <Label for="loanAmount">Loan amount</Label>
                    <Field
                      onChange={(e) => {
                        const downPayment =
                          values["propertyPrice"] - parseInt(e.target.value, 10);
                        setFieldValue("downPayment", downPayment);
                        setFieldValue("loanAmount", parseInt(e.target.value, 10));
                      }}
                      name="loanAmount"
                      id="totalLoan"
                      placeholder="New property price"
                      component={FormikReactStrapInput}
                      type="number"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="12" lg="6">
                    <Label for="desirePaymentTerm">Loan tenure(years)</Label>
                    <Field
                      name="desirePaymentTerm"
                      id="desirePaymentTerm"
                      placeholder="Desire payment term"
                      component={FormikReactStrapInput}
                      type="number"
                    />
                  </Col>
                  <Col md="12" lg="6">
                    <Label for="loanInsurance">
                      Loan insurance &nbsp;
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        id="LOAN_INSURANCE_TOOLTIP"
                      />
                      <UncontrolledTooltip target="LOAN_INSURANCE_TOOLTIP">
                        Loan Insurance is calculated based on 5% of property
                        price. The selected type in this calculator is MRTA.
                        Loan insurance is a way to protect your property in case
                        of any unfortunate incidents. The amount depends on
                        individual risk factor. MRTA will also affect interest
                        rate. MRTA is optional but highly recommended.
                      </UncontrolledTooltip>
                    </Label>
                    <Field
                      name="loanInsuranceType"
                      id="loanInsuranceType"
                      type="select"
                      component={FormikReactStrapInput}
                    >
                      <option value="INTO_LOAN">Bundle with loan</option>
                      <option value="UPFRONT_CASH">Lump sum by cash</option>
                      <option value="NONE">None</option>
                    </Field>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="isPropertyWithManagement"
                    id="isPropertyWithManagement"
                    component={FormikReactStrapCustomInput}
                    type="checkbox"
                    label={
                      <>
                        Property with management fee &nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          id="PROPERTY_WITH_MANAGEMENT_TOOLTIP"
                        />
                        <UncontrolledTooltip target="PROPERTY_WITH_MANAGEMENT_TOOLTIP">
                          Strata property including condominiums and apartments
                          usually comes with management fee.
                        </UncontrolledTooltip>
                      </>
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="isCommercial"
                    id="isCommercial"
                    component={FormikReactStrapCustomInput}
                    type="checkbox"
                    label={
                      <>
                        Commercial title &nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          id="COMMERCIAL_TITLE_TOOLTIP"
                        />
                        <UncontrolledTooltip target="COMMERCIAL_TITLE_TOOLTIP">
                          e.g. Serviced apartment.
                        </UncontrolledTooltip>
                      </>
                    }
                  />
                </FormGroup>
                <FormGroup row>
                  <Col md="12" lg="6">
                    <Label for="desirePropertySize">Property size</Label>
                    <InputGroup>
                      <Field
                        name="desirePropertySize"
                        id="desirePropertySize"
                        placeholder="Estimated property size in square feet"
                        component={FormikReactStrapInput}
                        type="number"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>sq ft</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>

                  {values["isPropertyWithManagement"] ? (
                    <Col md="12" lg="6">
                      <Label for="main">Maintenance Fee</Label>
                      <InputGroup>
                        <Field
                          name="maintenanceFeePerSqft"
                          id="maintenanceFeePerSqft"
                          placeholder="Estimated maintanance in square feet"
                          component={FormikReactStrapInput}
                          type="number"
                          step={0.1}
                          min={0}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>per sq ft</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  ) : undefined}
                </FormGroup>
                <FormGroup>
                  <Label for="nrOfHousehold">
                    Expected number of household members
                  </Label>
                  <Field
                    name="nrOfHousehold"
                    id="nrOfHousehold"
                    placeholder="Expected number of household"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>

                {/* <FormGroup>
                  <Label for="otherAsset">Other asset</Label>
                  <Input
                    name="grossIncome"
                    id="grossIncome"
                    placeholder="Nett income after EPF/SOSCO/PCB"
                  />
                </FormGroup> */}
              </CardBody>
            </Card>
            <Card className="card-border-right">
              <CardHeader className="calculator-card-header">Income</CardHeader>
              <CardBody>
                <FormGroup>
                  <Field
                    name="employementType"
                    id="employementType_FULLTIME"
                    component={FormikReactStrapCustomInput}
                    type="radio"
                    label="Full-time employed"
                    value="FULLTIME"
                  />
                  <Field
                    name="employementType"
                    id="employementType_SELF_EMPLOYED"
                    component={FormikReactStrapCustomInput}
                    type="radio"
                    label="Self-employed"
                    value="SELF_EMPLOYED"
                  />
                  <Field
                    name="employementType"
                    id="COMMISION_EARNER_employementType"
                    component={FormikReactStrapCustomInput}
                    type="radio"
                    label={
                      <>
                        Commission earner &nbsp;
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          id="COMMISION_EARNER_employementType_info"
                        />
                        <UncontrolledTooltip target="COMMISION_EARNER_employementType_info">
                          e.g. Full time insurance agent, property agent etc.
                        </UncontrolledTooltip>
                      </>
                    }
                    value="COMMISION_EARNER"
                  />
                </FormGroup>
                {values["employementType"] === "FULLTIME" ? (
                  <FormGroup>
                    <Label for="nettIncome">
                      Nett income &nbsp;
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        id="NETT_INCOME_TOOLTIP"
                      />
                      <UncontrolledTooltip target="NETT_INCOME_TOOLTIP">
                        Nett amount received in bank after deductions.
                      </UncontrolledTooltip>
                    </Label>
                    <Field
                      name="nettIncome"
                      id="nettIncome"
                      placeholder="Nett income after EPF/SOSCO/PCB"
                      component={FormikReactStrapInput}
                      type="number"
                    />
                  </FormGroup>
                ) : undefined}
                {values["employementType"] === "SELF_EMPLOYED" ? (
                  <>
                    <FormGroup>
                      <Label for="averageSixMonthBankCreditBalance">
                        6 months average bank credit balance
                      </Label>
                      <Field
                        name="averageSixMonthBankCreditBalance"
                        id="averageSixMonthBankCreditBalance"
                        placeholder="Average monthly bank credit balance (in 6 months)"
                        component={FormikReactStrapInput}
                        type="number"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="nettReportedIncome">
                        Monthly nett reported income
                      </Label>
                      <Field
                        name="nettReportedIncome"
                        id="nettReportedIncome"
                        placeholder="Nett reported income"
                        component={FormikReactStrapInput}
                        type="number"
                      />
                    </FormGroup>
                  </>
                ) : undefined}
                {values["employementType"] === "COMMISION_EARNER" ? (
                  <FormGroup>
                    <Label for="averageSixMonthIncome">
                      6 months average income (commision earner)
                    </Label>
                    <Field
                      name="averageSixMonthIncome"
                      id="averageSixMonthIncome"
                      placeholder="Average 6 months income"
                      component={FormikReactStrapInput}
                      type="number"
                    />
                  </FormGroup>
                ) : undefined}

                <FormGroup>
                  <Label for="otherIncome">
                    Other income (rental, FD interest etc.)
                  </Label>
                  <Field
                    name="otherIncome"
                    id="otherIncome"
                    placeholder="Other income"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                <div className="calculator-form-sum-container">
                  <span className="sum-title">Total: </span>
                  <span className="calculator-currency">{currency}&nbsp;</span>
                  <span className="sum-value">
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        values["nettIncome"] +
                        values["averageSixMonthBankCreditBalance"] +
                        values["nettReportedIncome"] +
                        values["averageSixMonthIncome"] +
                        values["otherIncome"]
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              </CardBody>
            </Card>
            <Card className="card-border-none">
              <CardHeader className="calculator-card-header">
                Commitment
              </CardHeader>
              <CardBody>
              <FormGroup>
                  <Label for="monthlyVehicleLoanPayment">
                    Monthly vehicle loan payment &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="vehicleLoanTooltip" />
                    <UncontrolledTooltip target="vehicleLoanTooltip">
                      Motor, car, or vehicle under hire purchase
                    </UncontrolledTooltip>
                  </Label>
                  <Field
                    name="monthlyVehicleLoanPayment"
                    id="monthlyVehicleLoanPayment"
                    placeholder="Monthly vehicle loan payment"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="monthlyHouseLoanPayment">
                    Monthly house loan payment
                  </Label>
                  <Field
                    name="monthlyHouseLoanPayment"
                    id="monthlyHouseLoanPayment"
                    placeholder="Monthly house loan payment"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="otherMontlyLoan">
                    Other monthly installment &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="otherLoanTooltip" />
                    <UncontrolledTooltip target="otherLoanTooltip">
                      Sum of monthly personal loan, PTPTN installment, installment from other institution
                    </UncontrolledTooltip>
                  </Label>
                  <Field
                    name="otherMontlyLoan"
                    id="monthlyHouseotherMontlyLoanLoanPayment"
                    placeholder="Monthly house loan payment"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="totalmonthlyLoan">
                    Monthly total loan payment &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} id="loanTooltip" />
                    <UncontrolledTooltip target="loanTooltip">
                      Sum of car, house & personal loan, and PTPTN
                    </UncontrolledTooltip>
                  </Label>
                  <Field
                    name="totalmonthlyLoan"
                    id="totalLoan"
                    placeholder="House/Car/personal loan"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="creditCardOutStanding">
                    Credit card total outstanding &nbsp;
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="ccreditCardOutStandingTooltip"
                    />
                    <UncontrolledTooltip target="ccreditCardOutStandingTooltip">
                      Calculation will take 5% of the outstanding as commitment
                    </UncontrolledTooltip>
                  </Label>
                  {/* show tool tip on it will take 5% of the total balance*/}
                  <Field
                    name="creditCardOutStanding"
                    id="creditCardOutStanding"
                    placeholder="Currently credit card total outstanding amount"
                    component={FormikReactStrapInput}
                    type="number"
                  />
                </FormGroup>
                <div className="calculator-form-sum-container">
                  <span className="sum-title">Total: </span>
                  <span className="calculator-currency">{currency}&nbsp;</span>
                  <span className="sum-value">
                    <NumberFormat
                      className="calculator-amount"
                      value={
                        values["monthlyHouseLoanPayment"] +
                        values["monthlyVehicleLoanPayment"] +
                        values["otherMontlyLoan"] +
                        values["creditCardOutStanding"] * 5 / 100
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              </CardBody>
            </Card>
          </CardGroup>
          <div className="calculator-tnc">
            <FormGroup>
              <Field
                name="agreedTnC"
                id="agreedTnC"
                component={FormikReactStrapCustomInput}
                type="checkbox"
                label={
                  <>
                    By submitting this form, I agree to{" "}
                    <Button
                      onClick={toggleTOUModal}
                      color="link"
                      className="airuma-link"
                    >
                      AIRUMA Terms of Use
                    </Button>{" "}
                    &amp;{" "}
                    <Button
                      onClick={togglePrivacyModal}
                      color="link"
                      className="airuma-link"
                    >
                      Privacy Policy
                    </Button>
                  </>
                }
              />
              {/* <Label check>
              <Input type="checkbox" /> 
            </Label> */}
            </FormGroup>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(({ calculator, app }) => ({ calculator, app }))(
  BasicInfoForm
);
