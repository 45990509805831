import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MainLayout from './layouts/MainLayout';
import initializeAxios from './services/initializeAxios'

import "./App.scss";

initializeAxios();

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/" name="Home" render={props => <MainLayout {...props}/>} />
        </Switch>
    </Router>
  );
}

export default App;
