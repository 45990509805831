import React, { useEffect } from "react";
import { Row, Col, CardHeader, Card, CardBody, Table } from "reactstrap";

const Financial = (props) => {
  const { calculatorResult } = props;
  const banks = calculatorResult.banks;

  return (
    <Row className="watermark_container">
      <div className="watermark_wrapper">
        <div className="watermark_text">Coming Soon</div>
      </div>
      <Col md="8" xs="12">
        <Row>
          <Col xs="12">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Costs of investment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MoT</td>
                  <td></td>
                </tr>
                <tr>
                  <td>MoT</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Stamp duty</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Loan agreement</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Valuation report</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Consent fee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Consent fee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Down payment and differential sum</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Property and land tax</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Sewerage fee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Maintenance fee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Management fee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Management fee</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
      <Col md="4" xs="12">
        <Card>
          {/* <CardHeader></CardHeader> */}
          <CardBody style={{ textAlign: "center" }}>
            <div style={{ height: "auto" }}>
              <span>Your investment of property will give you </span>
              <br />
              <span style={{ fontSize: "68px", fontWeight: "bold" }}>8%</span>
              <span>&nbsp;per annum</span>
              <br />
              <span>From rental income after netting off expeses</span>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Financial;
