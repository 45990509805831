import React from "react";
import { Row, Col, Container } from "reactstrap";

import airumaLogo from "../../resources/img/airuma-logo.png";

const FooterComponent = () => {
  return (
    <div className="footer mt-1">
      <Container fluid>
        <Row>
          <Col md="2">
            <img
              src={airumaLogo}
              alt="AIRUMA logo"
              style={{ height: "40px" }}
            />
            <small className="d-block mb-3 text-muted">&copy; 2021</small>
          </Col>
          <Col md="3">
            <h5>About</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="link-secondary" href="/about-us">
                  About Us
                </a>
              </li>
              {/* <li><a className="link-secondary" href="#">Team</a></li>
        <li><a className="link-secondary" href="#">Locations</a></li> */}
              <li>
                <a className="link-secondary" href="/policies/privacy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="link-secondary" href="/policies/terms-of-use">
                  Terms of Use
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterComponent;
