import React from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import TermsOfUseComponent from '../../../Polices/TermsOfUse'
const TOUModal = (props) => {
  const {isOpen, toggle} = props
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Terms of Use
      </ModalHeader>
      <ModalBody>
        <TermsOfUseComponent />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default TOUModal;
