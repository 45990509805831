import React from "react";

const Privacy = () => {
  return (
    <div>
      <h3>PRIVACY POLICY</h3>

      <h4>Introduction</h4>
      <ul>
        <li>
          AIRUMA respects and protects your personal data and information in
          accordance to the Personal Data Protection Act 2010 (‘PDPA 2010’).
          This Privacy Notice sets out how AIRUMA, a brand owned by LEXTREE PLT
          (‘AIRUMA’ / ‘LEXTREE’ / ‘We’) collects your personal data and
          information and how it is used on our website www.airuma.com.my
          (‘website’).
        </li>
        <li>
          The website is not intended for use by any persons who have not
          reached their legal age (e.g. minors) or persons who are incapable of
          managing their own affairs or mentally incapacitated. If you fall
          within any of these categories then please stop using this website. By
          your continued use of the website, you warrant that you are of legal
          age and of sound mind to access the services/products on the website.
        </li>
        <li>
          This Privacy Policy sets out the manner in which we collect, use,
          disclose, store, process and manage personal information. By visiting
          or using the website, subscribing to our services or entering into an
          agreement with us in relation to the website, you are taken to have
          read and consented to the collection, use, disclosure, storage,
          processing and handling of your personal information in accordance
          with this Privacy Policy. We may modify this Privacy Policy at any
          time. If you continue to use the website after the Privacy Policy had
          been updated, you are deemed to have accepted to be bound by the new
          Privacy Policy.
        </li>
      </ul>
      <h4>Collection of Personal Information</h4>
      <ul>
        <li>
          AIRUMA collects your personal information when you:
          <ul>
            <li>
              voluntarily applies online to use our online products or services
              (e.g. AIRUMA Property Calculator); or
            </li>
            <li>when you sign up for our weekly newsletters.</li>
          </ul>
        </li>
        <li>
          {" "}
          We may collect personal information about you including, but not
          limited to your name, age, identification, address, phone number,
          email, gender, occupation, personal interests, financial information,
          and other information necessary or required for AIRUMA to perform its
          services.
        </li>
        <li>
          We may also collect non-personal information about you including, but
          not limited to, data relating to your activities on the website via
          tracking technologies or data relating to survey responses.
        </li>
        <li>
          You acknowledge that the personal information you provide us and which
          we collect from you, is your own information or information which you
          have been authorised to provide to us.
        </li>
      </ul>
      <h4>Use of Personal Information</h4>
      <ul>
        <li>
          {" "}
          By using our products and services in AIRUMA’s website, we may collect
          and use your personal information for the following uses
          <ul>
            <li>To enable you to access and use AIRUMA’s services.</li>
            <li>
              To send you newsletters or information about products and services
              which may be of interest to you.
            </li>
            <li>
              To communicate with you, in order to verify the personal
              information and data provided, or to request any additional
              information that is essential to fulfilling the services that you
              applied.
            </li>
            <li>
              {" "}
              To provide tailored and personalized contents to you and our other
              users.
            </li>
            <li>
              To carry out research such as analyzing market trends and customer
              demographics.
            </li>
            <li>
              To comply with any applicable rules, laws and regulations, codes
              of practice or guidelines or to assist in law enforcement and
              investigations by relevant authorities.
            </li>
          </ul>
        </li>
        <li>
          We may not use your personal information for direct marketing or share
          your information with others for direct marketing unless we have
          received your consent. Further, we will give you the opportunity to
          request that your information not to be used for further direct
          marketing in the future in our communications with you.
        </li>
      </ul>
      <h4>Retaining and Sharing of your Personal Information</h4>
      <ul>
        <li>
          AIRUMA keeps your personal and financial information for only as long
          as it requires to provide you with our services. AIRUMA may also share
          your personal information with third party providers whose products
          are listed on AIRUMA’s website. AIRUMA will not sell, distribute, or
          lease your personal information to third parties without your consent,
          unless required to do so under the law. AIRUMA will not send you
          promotional information without your consent.
        </li>
      </ul>
      <h4>Access to Data</h4>
      <ul>
        <li>
          If you wish to access your data or to correct personal information
          that is inaccurate, incomplete, misleading or not up to date or should
          you wish to withdraw consent for the use of your personal information,
          please contact us at lextreemy@gmail.com.
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
