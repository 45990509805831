import React from 'react';
import './style.scss';

const ResultBasicDetail = ({ children }) => {
  return (
    <div className="resultCardPropertyProperties">
      <span>
        {children}
      </span>
  </div>
  )
}

export default ResultBasicDetail;
