export default {
  name: "detail",
  state: {
    unitDetail: {
      lat: 1.2222,
      long: 2.2222,
      name: 'The Vyne',
      state: 'Kuala Lumpur',
      description: 'Default location text',
      propertyType: 'condominium', // landed, service apertment
      startingPrice: 1234039.00,
      bedroom: 1,
      bathroom: 1,
      unitArea: 1234,
      areaMeasurementUnit: 'sqft',
      totalStorey: 40,
      locatedStorey: 20,
      tenure: 'leasehold', //freehold
      propertyTitle: 'Commercial', // individual, strata, master
      estimatedmonthlyPayment: 1234.00,
      currency: 'RM'
    },
    amenties: {
      schools: [{
        name: 'SJKC Chong Hwa',
        lat: 1.2,
        long: 1.1
      },
      {
        name: 'Private School',
        lat: 1.2,
        long: 1.1
      }],
      publicTransports: [{
        name: 'MRT station',
        lat: 1.2,
        long: 1.1
      },
      {
        name: 'LRT station',
        lat: 1.2,
        long: 1.1
      }],
      highways: [{
        name: 'MEX'
      }],
      malls: [
        {
          name: 'Mid valley',
          distance: '1.2',
          uom: 'km'
        }
      ],
      restaurants: [{
        name: 'long sheng',
        lat: 1.2,
        long: 1.1
      }],
      religiousFacility: [{
        name: 'abcd mosque',
        lat: 1.1,
        long: 1.1
      }]
    },
    nearbyOptions: [// this should show the aggregated result by project
      {
        id: "a-1",
        projectName: 'PJ highrise',
        state: 'Selangor',
        town: 'Sungai Besi',
        startPrice: 123000,
        maxPrice: 123000,
        distance: 1,
        distanceUOM: 'km',
        currency: 'RM',
        units: 4,
      },
      {
        id: "a-2",
        projectName: 'Kepong highrise',
        state: 'Selangor',
        town: 'Kepong',
        startPrice: 123000,
        maxPrice: 123000,
        distance: 1,
        distanceUOM: 'km',
        currency: 'RM',
        units: 5,
      }
    ]
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload;
    },
    saveListings(state, payload) {
      return {
        ...state,
        listings: payload
      }
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions.
    async fetchListingDetail(payload) {
      console.log(`search for payload ${payload}`)
      await new Promise((resolve) => setTimeout(resolve, 1500));
      // dispatch.listing.saveListings([
      //   'abcdfgh'
      // ])
    },
    async incrementAsync(payload, rootState) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch.listing.increment(payload);
    },
  }),
};
