import { getLoanBankDetail } from "../services/services/calculator";

const appModel = {
  name: "app",
  state: {
    currency: "RM",
  }, // initial state
  reducers: {
  },
  effects: (dispatch) => ({
   
  }),
};

export default appModel;