import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap'

import defaultImg from '../../../resources/img/logo192.png'
import '../style.css'
const DetailCardOtherOptions = (props) => {
  const { nearbyOptions } = props
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={{ size: 8 }} style={{ minHeight: '200px'}}>
            <h5>Other options</h5>
            {
              nearbyOptions.map(e => (
                <div className="detailTopCard" key={e.id}>
                  <div style={{ width: "35%", textAlign: 'center', borderRight: 'solid 1px rgba(0,0,0,.125)' }}>
                    <img src={e.imgSrc === '' || !e.imgSrc ? defaultImg : e.imgSrc} alt="Propety" style={{ maxWidth: '100%', maxHeight: '100%'}}/>
                  </div>
                  <div style={{ width: "65%", margin: "10px" }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {}}>
                      <h6>{e.projectName}</h6>
                    </div>
                    <div><span>Location: {`${e.town} ${e.state}`}</span></div>
                    <div><span>Distance: {e.distance} {e.distanceUOM}</span></div>
                    <div><span>Unit Left: {e.units}</span></div>
                    <div><span>Price range: {`${e.currency} ${e.startingPrice}`} - {`${e.currency} ${e.maxPrice}`}</span></div>
                  </div>
                </div>
              ))
            }
          </Col>
          <Col md={{ size: 4 }}>
            MAP again
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
};

export default DetailCardOtherOptions;
