import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap";

import BlogCard from "./components/BlogCard";
import BlogCard2 from "./components/BlogCard2";

const Blog = () => {
  return (
    <div style={{ padding: "3% 10% 2% 10%",  }}>
      {/* <h3>Blogs</h3> */}
      <Row>
        <Col xs="6" md="3">
          <BlogCard />
        </Col>
        <Col xs="6" md="3">
          <BlogCard />
        </Col>
        <Col xs="6" md="3">
          <BlogCard />
        </Col>
        <Col xs="6" md="3">
          <BlogCard />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <h2>Latest</h2>
          <BlogCard2 />
          <BlogCard2 />
          <BlogCard2 />
          <BlogCard2 />
        </Col>
        <Col md="6">
          <h2>Hottest</h2>
          <BlogCard2 />
        </Col>
      </Row>
    </div>
  );
};
export default Blog;
