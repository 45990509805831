import { init } from '@rematch/core'
import loadingPlugin from '@rematch/loading'

import listing from './listing'
import detail from './detail'
import booking from './booking'
import calculator from './calculator'
import app from './app'

const store = init({
  name: "airumaStore",
  models: {
    listing,
    detail,
    booking,
    calculator,
    app
  },
  plugins: [loadingPlugin()],
})

export default store