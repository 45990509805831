import HomePage from './pages/home';
import HomePage2 from './pages/home/index2';
import ResultPage from './pages/result';
import DetailPage from './pages/detail';
import BookingPage from './pages/booking';
import Blog from './pages/blog';
import Privacy from './pages/policies/Privacy';
import TOU from './pages/policies/TermsOfUse';
import ShareNow from './pages/share';
import AboutUs from './pages/AboutUs';

import Calculator from './pages/calculator'

const routes = [
  // {
  //   name: "homepage",
  //   path: "/",
  //   component: HomePage,
  //   exact: true
  // },
  {
    name: "homepage",
    path: "/",
    component: HomePage2,
    exact: true
  },
  {
    name: "homepage",
    path: "/2",
    component: HomePage,
    exact: true
  },
  {
    name: "Calculator",
    path: "/calculator",
    component: Calculator,
    exact: true
  },
  {
    name: "Listing",
    path: "/listing",
    component: ResultPage,
    exact: true
  },
  {
    name: "Detail",
    path: "/detail",
    component: DetailPage,
    exact: true
  },
  {
    name: "Listing",
    path: "/booking",
    component: BookingPage,
    exact: true
  },
  {
    name: "Learn",
    path: "/blog/learn",
    component: Blog,
    exact: true
  },
  {
    name: "Calculator",
    path: "/cal_test",
    component: Calculator,
    exact: true
  },
  {
    name: "TermOfUse",
    path: "/policies/terms-of-use",
    component: TOU,
    exact: true
  },
  {
    name: "Privacy",
    path: "/policies/privacy",
    component: Privacy,
    exact: true
  },
  {
    name: "Share now!",
    path: "/share-now",
    component: ShareNow,
    exact: true
  },
  {
    name: "About us",
    path: "/about-us",
    component: AboutUs,
    exact: true
  }
  // {
  //   path: "/tacos",
  //   component: Tacos,
  //   routes: [
  //     {
  //       path: "/tacos/bus",
  //       component: Bus
  //     },
  //     {
  //       path: "/tacos/cart",
  //       component: Cart
  //     }
  //   ]
  // }
];

export default routes