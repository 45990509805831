import React, { useState } from "react";
import { Input, FormGroup, Label } from 'reactstrap';
import AIRUMAAnalysis from "./AirumaAnalysis";
const Financial = (props) => {
  const { calculatorResult, selectedBank } = props;
  // const [selectedBank, setSelectedBank] = useState();

  // const onRadioChange = (event, bank) => {
  //   setSelectedBank(bank)
  // }

  return (
    <div style={{ paddingTop: "15px" }}>
      {/* Based on your profile, these are the bank that might fulfill your dream
      home. */}
      {/* <ul> */}
        {/* {calculatorResult.banks &&
          calculatorResult.banks.map((e) => (
            // <li>
            //   {e.bankName} - {e.bestRate}% - {e.chances}
            // </li>
            <FormGroup check key={e.bankName}>
              <Input
                id={e.bankName}
                type="radio"
                name="radio"
                checked={e.bankName === selectedBank ? selectedBank.bankName : undefined} 
                onChange={(ele) => onRadioChange(ele, e)} 
              />
              <Label check for={e.bankName}>
                {e.bankName} - {e.bestRate}% - {e.chances}
              </Label>
            </FormGroup>
          ))} */}
      {/* </ul> */}
      <AIRUMAAnalysis selectedBank={selectedBank} calculatorResult={calculatorResult} />
    </div>
  );
};

export default Financial;
